import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';
import { ThemeProvider } from 'styled-components';
import { Box, Flex } from 'rebass';
import { get, isEmpty, map, isEqual, size } from 'lodash/fp';

import { ProductCard } from '../ProductCard/ProductCard';
import { productImpressionEvent } from '../../helpers/gtm';

const ProductListSwiper = dynamic(
  () =>
    import('../CustomSwiper/ProductListSwiper').then(
      (mod) => mod.ProductListSwiper
    ),
  {
    loading: () => <div />,
  }
);

const calculateIfSwipeShouldBeActive = (products, screenWidth) => {
  let swipeActive = true;

  if (screenWidth > 1280) {
    swipeActive = size(products) > 4;
  } else if (screenWidth > 820) {
    swipeActive = size(products) > 3;
  } else if (screenWidth > 320) {
    swipeActive = size(products) > 2;
  }

  return swipeActive;
};

export const SwipeProductList = (props) => {
  const {
    breakpoints,
    grid,
    gridItemPadding,
    gridMargin,
    swipe,
    autoplayDelay,
    products,
  } = props;
  const [previousProductData, setPreviousProductData] = useState();
  const [productsPerRow, setProductsPerRow] = useState(
    calculateIfSwipeShouldBeActive(products, window.innerWidth)
  );

  const calculateProductsPerRow = () => {
    const swipeActive = calculateIfSwipeShouldBeActive(
      products,
      window.innerWidth
    );
    setProductsPerRow(swipeActive);
  };

  useEffect(() => {
    window.addEventListener('resize', calculateProductsPerRow);

    return () => {
      window.removeEventListener('resize', calculateProductsPerRow);
    };
  }, []);

  useEffect(() => {
    if (!isEmpty(products) && !isEqual(products, previousProductData)) {
      productImpressionEvent(products);
    }

    setPreviousProductData(products);
  }, [products]);

  if (swipe && productsPerRow) {
    const offsetArrowIcon = 75; // The height of the text portion of the ProductCard divided by 2
    const autoplay =
      typeof autoplayDelay === 'number' ? { delay: autoplayDelay } : false;

    return (
      <ProductListSwiper offsetArrowIcon={offsetArrowIcon} autoplay={autoplay}>
        {map(
          (product) => (
            <ProductCard key={get('id', product)} product={product} />
          ),
          products
        )}
      </ProductListSwiper>
    );
  }

  const theme = {
    breakpoints,
  };

  return (
    <ThemeProvider theme={theme}>
      <Flex flexWrap="wrap" mx={gridMargin}>
        {map(
          (product) => (
            <Box
              key={get('id', product)}
              width={grid}
              px={gridItemPadding}
              py={10}
              style={{ display: 'inline-block' }}
            >
              <ProductCard product={product} />
            </Box>
          ),
          products
        )}
      </Flex>
    </ThemeProvider>
  );
};

SwipeProductList.defaultProps = {
  products: [],
  breakpoints: ['320px', '820px', '1280px'],
  grid: [1, 1 / 2, 1 / 3, 1 / 4],
  gridItemPadding: [0, '6px', '15px'],
  gridMargin: [0, '-6px', '-15px'],
  swipe: true,
  autoplayDelay: undefined,
};

SwipeProductList.propTypes = {
  products: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.object),
  ]),
  breakpoints: PropTypes.arrayOf(PropTypes.string),
  grid: PropTypes.array,
  gridItemPadding: PropTypes.array,
  gridMargin: PropTypes.array,
  swipe: PropTypes.bool,
  autoplayDelay: PropTypes.number,
};
