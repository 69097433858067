import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';
import styled, { ThemeProvider } from 'styled-components';
import { Box, Flex } from 'rebass';
import { get, isEmpty, toArray, isEqual } from 'lodash/fp';
import { useCookies } from 'react-cookie';

import { compose } from 'redux';
import { ProductCard } from '../ProductCard/ProductCard';
import { productImpressionEvent } from '../../helpers/gtm';
import { LAST_LIST_VIEW } from '../../constants/cookies';
import { connect } from '../../store/store';
import { LocalizedLink } from '../LocalizedLink/LocalizedLink';
import {
  FeaturedProductButtonContainer,
  FeatureProductsBlockButton,
} from '../../blocks/FeaturedProductsBlock/styles';
import Video from '../Video/Video';

const CustomSwiper = dynamic(
  () => import('../CustomSwiper/CustomSwiper').then((mod) => mod.CustomSwiper),
  {
    loading: () => <div />,
  }
);

const StyledDiv = styled.div`
  display: contents;
`;

function ProductListComponent(props) {
  // eslint-disable-next-line no-unused-vars
  const [_, setCookie] = useCookies();
  const {
    breakpoints,
    gridVideo,
    grid,
    gridItemPadding,
    gridMargin,
    swipe,
    products,
    routerHistory,
    buttonCollection,
    buttonColor,
    saveNumberOfLoadedProducts,
    currentPage,
    market,
    productListContent,
  } = props;
  const [previousProductData, setPreviousProductData] = useState();

  useEffect(() => {
    if (!isEmpty(products) && !isEqual(products, previousProductData)) {
      productImpressionEvent(products);
    }

    setPreviousProductData(products);
  }, [products]);

  const onGoToProduct = () => {
    if (saveNumberOfLoadedProducts) {
      setCookie(LAST_LIST_VIEW, {
        productsLength: products.length,
        path: routerHistory[routerHistory.length - 1],
      });
    }
  };

  if (swipe) {
    const offsetArrowIcon = 75; // The height of the text portion of the ProductCard divided by 2
    return (
      <CustomSwiper
        offsetArrowIcon={offsetArrowIcon}
        breakpoints={{
          320: {
            slidesPerView: 2,
            centeredSlides: false,
          },
          900: {
            slidesPerView: 3,
            centeredSlides: false,
          },
        }}
      >
        {toArray(products).map((product, index) => (
          <StyledDiv key={get('id', product)}>
            <ProductCard product={product} />
            {buttonCollection && buttonCollection[index] && (
              <FeaturedProductButtonContainer>
                <LocalizedLink
                  key={buttonCollection[index].link.id}
                  as={buttonCollection[index].link.id}
                  data={{ id: buttonCollection[index].link.id }}
                  page={buttonCollection[index].link.type}
                >
                  <FeatureProductsBlockButton as="a">
                    {buttonCollection[index].heading}
                  </FeatureProductsBlockButton>
                </LocalizedLink>
              </FeaturedProductButtonContainer>
            )}
          </StyledDiv>
        ))}
      </CustomSwiper>
    );
  }

  const theme = {
    breakpoints,
  };

  const listingContent = currentPage.productListingContent
    ? currentPage.productListingContent
    : productListContent;

  return (
    <ThemeProvider theme={theme}>
      <Flex flexWrap="wrap" mx={gridMargin}>
        {toArray(products).map((product, index) => {
          return (
            <StyledDiv key={`${get('id', product)}-${index}`}>
              {index !== 0 &&
                (index - 4) % 8 === 0 &&
                listingContent &&
                listingContent[(index - 4) / 8] && (
                  <Box
                    px={gridItemPadding}
                    py={10}
                    width={gridVideo}
                    style={{ display: 'inline-block', overflow: 'hidden' }}
                  >
                    <Video
                      loop
                      hideButton
                      playOnViewport
                      m="20px 0"
                      inheritHeight
                      relative
                      link={listingContent[(index - 4) / 8].link}
                      src={listingContent[(index - 4) / 8].video?.urlPath}
                      cover={listingContent[(index - 4) / 8].image?.urlPath}
                      market={market}
                      playsInline
                      muted
                      autoplay
                    />
                  </Box>
                )}
              <Box
                width={grid}
                px={gridItemPadding}
                py={10}
                style={{ display: 'inline-block' }}
              >
                <>
                  <ProductCard
                    listIndex={index}
                    product={product}
                    onGoToProduct={onGoToProduct}
                  />

                  {buttonCollection && buttonCollection[index] && (
                    <FeaturedProductButtonContainer>
                      <LocalizedLink
                        key={buttonCollection[index].link.id}
                        as={buttonCollection[index].link.id}
                        data={{ id: buttonCollection[index].link.id }}
                        page={buttonCollection[index].link.type}
                      >
                        <FeatureProductsBlockButton
                          as="a"
                          isBlack={buttonColor === 'whiteOnBlack'}
                          isWhite={buttonColor === 'blackOnWhite'}
                          whiteBorder={buttonColor === 'whiteBorder'}
                          blackBorder={buttonColor === 'blackBorder'}
                        >
                          {buttonCollection[index].heading}
                        </FeatureProductsBlockButton>
                      </LocalizedLink>
                    </FeaturedProductButtonContainer>
                  )}
                </>
              </Box>
            </StyledDiv>
          );
        })}
      </Flex>
    </ThemeProvider>
  );
}

ProductListComponent.defaultProps = {
  currentPage: {},
  products: [],
  breakpoints: ['480px', '820px', '1280px'],
  gridVideo: [1, 1, 1, 1 / 2],
  grid: ['50%', '50%', 1 / 3, 1 / 4],
  gridItemPadding: ['6px', '6px', '15px'],
  gridMargin: ['-6px', '-6px', '-15px'],
  swipe: false,
  routerHistory: [],
  buttonCollection: [],
  buttonColor: null,
  saveNumberOfLoadedProducts: false,
  market: '',
  productListContent: [],
};

ProductListComponent.propTypes = {
  currentPage: PropTypes.object,
  products: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.object),
  ]),
  breakpoints: PropTypes.arrayOf(PropTypes.string),
  gridVideo: PropTypes.array,
  grid: PropTypes.array,
  gridItemPadding: PropTypes.array,
  gridMargin: PropTypes.array,
  swipe: PropTypes.bool,
  routerHistory: PropTypes.arrayOf(PropTypes.string),
  buttonCollection: PropTypes.array,
  buttonColor: PropTypes.string,
  saveNumberOfLoadedProducts: PropTypes.bool,
  market: PropTypes.string,
  productListContent: PropTypes.array,
};

export const ProductList = compose(connect)(
  ProductListComponent,
  {},
  (store) => ({
    routerHistory: get(['viewState', 'routerHistory'], store),
  })
);
