import React from 'react';
import PropTypes from 'prop-types';

import { ContainerRoot } from '../../components/Container/styles';
import { MagicButtons } from '../../components/MagicButtons/MagicButtons';

export const CTABlock = props => {
  const { draftId, buttons, buttonColor, displayOnMobile, displayOnDesktop } = props;

  return (
    <ContainerRoot
      data-litium-block-id={draftId}
      displayOnMobile={displayOnMobile}
      displayOnDesktop={displayOnDesktop}
      as="section"
    >
      <MagicButtons buttons={buttons} buttonColor={buttonColor} centerButtons hideOnMobile />
    </ContainerRoot>
  );
};

CTABlock.defaultProps = {
  draftId: undefined,
  buttons: [],
  buttonColor: null,
  displayOnMobile: true,
  displayOnDesktop: true
};

CTABlock.propTypes = {
  draftId: PropTypes.string,
  buttons: PropTypes.array,
  buttonColor: PropTypes.string,
  displayOnMobile: PropTypes.bool,
  displayOnDesktop: PropTypes.bool
};
