import React, { Component, Fragment } from 'react';
import { map, size } from 'lodash/fp';
import PropTypes from 'prop-types';
import { Box, Flex } from 'rebass';
import { ThemeProvider } from 'styled-components';
import { Puff } from '../Puff/Puff';
import { margins, sizes } from '../../styles/variables';

export class PuffList extends Component {
  render() {
    const {
      breakpoints,
      puffs,
      grid: gridProps,
      language,
      presentationMode,
      preserveAspectRatio,
      headingFont,
    } = this.props;
    const centerChildren = size(puffs) < 3;
    const isTwoPuffs = size(puffs) === 2;
    const grid = isTwoPuffs ? [1, 0.5] : gridProps; // !INFO: sets grid to 2 or 3 columns

    const imageWidth = 1920 / puffs?.length || 1920 / 2;
    const imageSizes = isTwoPuffs
      ? '(max-width: 1080px) 50vw, 750px'
      : '(min-width: 1080px) 33vw, 640px';

    const hasContentBelowImage = puffs.some((p) => {
      if (presentationMode)
        return (
          p.subHeading ||
          p.publishedDate ||
          p.preamble ||
          p.shortPreamble ||
          p.body
        );
      return (
        p.heading ||
        p.subHeading ||
        p.publishedDate ||
        p.preamble ||
        p.shortPreamble ||
        p.body
      );
    });

    return (
      <ThemeProvider theme={{ breakpoints }}>
        <Flex
          as="ul"
          p={0}
          sx={{
            marginLeft: margins.xSmall,
            marginRight: margins.xSmall,
            [`@media screen and (min-width: ${sizes.small}px)`]: {
              marginLeft: margins.small,
              marginRight: margins.small,
            },
            [`@media screen and (min-width: 640px)`]: {
              marginLeft: 0,
              marginRight: 0,
            },
          }}
          mt={hasContentBelowImage ? -15 : '-5px'}
          flexWrap={hasContentBelowImage ? 'wrap' : 'nowrap'}
          style={{
            justifyContent: centerChildren ? 'center' : 'flex-start',
            gap: hasContentBelowImage ? 'initial' : '15px',
            // display: hasContentBelowImage ? 'inline-block' : 'flex',
          }}
        >
          {puffs && puffs?.length > 0
            ? puffs.map((puff, idx) => (
                <Box
                  key={idx}
                  as="li"
                  width={grid}
                  px={hasContentBelowImage ? 15 : 0}
                  py={hasContentBelowImage ? 10 : 0}
                  alignItems="center"
                  style={{ display: 'inline-block' }}
                >
                  <Puff
                    // key={puff.id || idx}
                    data={puff}
                    language={language}
                    presentationMode={presentationMode}
                    preserveAspectRatio={preserveAspectRatio}
                    isTwoPuffs={isTwoPuffs}
                    hasContentBelowImage={hasContentBelowImage}
                    headingFont={headingFont}
                    imageWidth={imageWidth}
                    imageSizes={imageSizes}
                  />
                </Box>
              ))
            : null}
        </Flex>
      </ThemeProvider>
    );
  }
}

PuffList.defaultProps = {
  puffs: {},
  breakpoints: ['620px', '1280px'],
  grid: [1, 1 / 2, 1 / 3],
  language: null,
  presentationMode: null,
  preserveAspectRatio: false,
  headingFont: '',
};
PuffList.propTypes = {
  puffs: PropTypes.array,
  grid: PropTypes.array,
  breakpoints: PropTypes.arrayOf(PropTypes.string),
  language: PropTypes.string,
  presentationMode: PropTypes.string,
  preserveAspectRatio: PropTypes.bool,
  headingFont: PropTypes.string,
};
