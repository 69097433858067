import React, { Component } from 'react';
import { get } from 'lodash/fp';
import PropTypes from 'prop-types';

import { LocalizedLink } from '../LocalizedLink/LocalizedLink';
import { formatDate } from '../../helpers/formatDate';
import { Conditional } from '../Conditional/Conditional';
import { MagicButtons } from '../MagicButtons/MagicButtons';
import { Truncate } from '../Truncate/Truncate';

import {
  PuffRoot,
  PuffImage,
  PuffVideo,
  PuffDescription,
  PuffImageContainer,
  PuffHeading,
  PuffLabel,
  PuffExternalLink,
  PuffImageOverlay,
  PuffImageOverlayTitle,
  MagicButtonContainer,
} from './styles';
import { connect } from '../../store/store';
import { EXTERNAL_PAGE } from '../../constants/cmsTypes';

const PRESENTATION_MODE = {
  TEXT_ON_IMAGE_BOTTOM: 'textonimagebottom',
  TEXT_ON_IMAGE_CENTERED: 'textonimagecentered',
  TEXT_ON_IMAGE_WITH_MAGIC_BUTTON: 'textonimagewithmagicbutton',
  TEXT_ON_IMAGE_WITH_CENTERED_MAGIC_BUTTON:
    'textonimagewithcenteredmagicbutton',
};

const MAGIC_BUTTON_PRESENTATION_MODES = [
  PRESENTATION_MODE.TEXT_ON_IMAGE_WITH_CENTERED_MAGIC_BUTTON,
  PRESENTATION_MODE.TEXT_ON_IMAGE_WITH_MAGIC_BUTTON,
];

class PuffComponent extends Component {
  render() {
    const {
      data,
      menu,
      language,
      presentationMode,
      isMobile,
      preserveAspectRatio,
      hasContentBelowImage,
      headingFont,
      isTwoPuffs,
    } = this.props;

    const {
      id,
      type,
      heading,
      subHeading,
      preamble,
      shortPreamble,
      body,
      publishedDate,
      images, // TODO: remove this in the near future
      image,
      mobileImage,
      heroImages,
      video,
      magicButtonHeading,
    } = data;
    const videoUrl = get([0, 'urlPath'], video);
    const backgroundVideo = videoUrl;
    const formattedDate = formatDate(publishedDate, language);
    const isLink = !!id;
    const isExternalLink = type === EXTERNAL_PAGE;
    const mode = presentationMode?.toLowerCase().trim();

    // Operations to get the correct image in the correct proportions
    const imageObject =
      images || image || mobileImage
        ? isMobile && mobileImage
          ? mobileImage
          : images?.length > 0
          ? get([0], images)
          : image
        : get([0], heroImages);

    const puffImageProps = preserveAspectRatio
      ? { layout: 'fixed' }
      : { heightRatio: 2 / 3, layout: 'fill' };

    const renderMagicButton = () => {
      const shouldRenderButton =
        MAGIC_BUTTON_PRESENTATION_MODES.includes(mode) && magicButtonHeading;
      if (!shouldRenderButton) return null;

      const buttons = [{ heading: magicButtonHeading, link: { id, type } }];
      return (
        <MagicButtonContainer
          stickToBottom={
            mode === PRESENTATION_MODE.TEXT_ON_IMAGE_WITH_MAGIC_BUTTON
          }
        >
          <MagicButtons buttons={buttons} buttonColor="whiteBorder" wide />
        </MagicButtonContainer>
      );
    };

    const puffImage = (
      <PuffImageContainer
        enableMarginBottom={hasContentBelowImage}
        displayFullHeightVideo={!!backgroundVideo && !hasContentBelowImage}
      >
        <Conditional show={!backgroundVideo}>
          <PuffImage
            src={get('urlPath', imageObject)}
            alt={get('alt', imageObject)}
            {...puffImageProps}
            height={get('height', imageObject) || 300}
            width={get('width', imageObject) || this.props.imageWidth || 400}
            sizes={
              this.props.isTwoPuffs || this.props.isMobile
                ? '(max-width: 1080px) 50vw, 750px'
                : '(min-width: 1080px) 33vw, 640px'
            }
          />
        </Conditional>
        <Conditional show={!!backgroundVideo}>
          <PuffVideo
            aspectRatio={isTwoPuffs && hasContentBelowImage ? 66.66 : null}
            videosrc={videoUrl}
            videoposterimage={get('urlPath', imageObject)}
            enableClipPath={false}
          />
        </Conditional>
        {(mode === PRESENTATION_MODE.TEXT_ON_IMAGE_BOTTOM ||
          mode === PRESENTATION_MODE.TEXT_ON_IMAGE_CENTERED ||
          mode === PRESENTATION_MODE.TEXT_ON_IMAGE_WITH_MAGIC_BUTTON ||
          mode ===
            PRESENTATION_MODE.TEXT_ON_IMAGE_WITH_CENTERED_MAGIC_BUTTON) && (
          <PuffImageOverlay
            center={
              mode === PRESENTATION_MODE.TEXT_ON_IMAGE_CENTERED ||
              mode === PRESENTATION_MODE.TEXT_ON_IMAGE_WITH_MAGIC_BUTTON ||
              mode ===
                PRESENTATION_MODE.TEXT_ON_IMAGE_WITH_CENTERED_MAGIC_BUTTON
            }
            pointerEvents={
              MAGIC_BUTTON_PRESENTATION_MODES.includes(mode) ? 'auto' : 'none'
            }
          >
            <PuffImageOverlayTitle headingFont={headingFont}>
              {heading}
            </PuffImageOverlayTitle>
            {renderMagicButton()}
          </PuffImageOverlay>
        )}
      </PuffImageContainer>
    );

    return (
      <PuffRoot enableMarginBlock={hasContentBelowImage}>
        {MAGIC_BUTTON_PRESENTATION_MODES.includes(mode) || !isLink ? (
          <>{puffImage}</>
        ) : isExternalLink ? (
          <PuffExternalLink href={id} rel="noopener noreferrer" target="_blank">
            {puffImage}
          </PuffExternalLink>
        ) : (
          <LocalizedLink as={id || '/'} page={type} data={{ id }}>
            <a>{puffImage}</a>
          </LocalizedLink>
        )}

        <Conditional show={!!get('subHeading', data)}>
          <PuffLabel>{subHeading}</PuffLabel>
        </Conditional>

        <Conditional show={!!formattedDate}>
          <PuffLabel>{formattedDate}</PuffLabel>
        </Conditional>

        {mode !== PRESENTATION_MODE.TEXT_ON_IMAGE_BOTTOM &&
          mode !== PRESENTATION_MODE.TEXT_ON_IMAGE_CENTERED &&
          mode !== PRESENTATION_MODE.TEXT_ON_IMAGE_WITH_MAGIC_BUTTON &&
          mode !== PRESENTATION_MODE.TEXT_ON_IMAGE_WITH_CENTERED_MAGIC_BUTTON &&
          heading && (
            <>
              {isLink ? (
                isExternalLink ? (
                  <PuffExternalLink
                    href={id}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <PuffHeading
                      menu={menu}
                      as="span"
                      headingFont={headingFont}
                    >
                      <Truncate lines={1}>{heading}</Truncate>
                    </PuffHeading>
                  </PuffExternalLink>
                ) : (
                  <LocalizedLink as={id || '/'} page={type} data={{ id }}>
                    <PuffHeading menu={menu} headingFont={headingFont}>
                      <Truncate lines={1}>{heading}</Truncate>
                    </PuffHeading>
                  </LocalizedLink>
                )
              ) : (
                <PuffHeading menu={menu} headingFont={headingFont} as="span">
                  <Truncate lines={1}>{heading}</Truncate>
                </PuffHeading>
              )}
            </>
          )}

        <Conditional show={!!preamble || !!shortPreamble}>
          <PuffDescription menu={menu} lines={2}>
            <Truncate lines={2}>{preamble || shortPreamble}</Truncate>
          </PuffDescription>
        </Conditional>

        <Conditional show={!preamble && !shortPreamble && !!body}>
          <PuffDescription
            menu={menu}
            lines={2}
            as="div"
            dangerouslySetInnerHTML={{ __html: body }}
          />
        </Conditional>
      </PuffRoot>
    );
  }
}

PuffComponent.defaultProps = {
  data: {},
  menu: false,
  presentationMode: null,
  preserveAspectRatio: false,
  isTwoPuffs: false,
  hasContentBelowImage: true,
  headingFont: '',
  imageWidth: 540,
  imageSizes: '100vw',
};

PuffComponent.propTypes = {
  data: PropTypes.object,
  menu: PropTypes.bool,
  language: PropTypes.string.isRequired,
  presentationMode: PropTypes.string,
  isMobile: PropTypes.bool.isRequired,
  preserveAspectRatio: PropTypes.bool,
  isTwoPuffs: PropTypes.bool,
  hasContentBelowImage: PropTypes.bool,
  headingFont: PropTypes.string,
  imageWidth: PropTypes.number,
  imageSizes: PropTypes.string,
};

export const Puff = connect(PuffComponent, {}, (store) => ({
  language: get(['viewState', 'language'], store),
  isMobile: get(['viewState', 'isMobile'], store),
}));
