/* eslint-disable react/no-unused-prop-types */
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { useSelector } from 'react-redux';
import Video from '../../components/Video/Video';
import {
  ContainerRoot,
  FullWidthContainer,
} from '../../components/Container/styles';
import useIOSVideoFix from '../../hooks/useIOSVideoFix';

export const VideoBlock = (props) => {
  const {
    fullWidth,
    videoAutoPlay,
    videoCoverImage,
    videoDescription,
    video,
    id,
    videoAutoMute,
    displayOnDesktop,
    displayOnMobile,
  } = props;
  const isMobile = useSelector((s) => s.viewState?.isMobile);

  if (isMobile && !displayOnMobile) return null;
  if (!isMobile && !displayOnDesktop) return null;

  const shouldLoop = videoAutoPlay;

  return (
    <VideoBlockRoot fullWidth={fullWidth}>
      <Video
        src={video?.urlPath}
        cover={videoCoverImage?.urlPath}
        playOnViewport
        hideButton
        muted={videoAutoMute}
        playsInline
        relative
        loop={shouldLoop}
        autoplay={videoAutoPlay}
        disableControls={false}
        videoDescription={videoDescription}
        ariaID={id}
      />
    </VideoBlockRoot>
  );
};

const VideoBlockRoot = ({ fullWidth, children }) => {
  if (fullWidth)
    return <FullWidthContainer as="section">{children}</FullWidthContainer>;
  return (
    <VideoBlockContainerRoot as="section">{children}</VideoBlockContainerRoot>
  );
};

const VideoBlockContainerRoot = styled(ContainerRoot)`
  position: relative;
`;

VideoBlock.defaultProps = {
  displayOnMobile: true,
  displayOnDesktop: true,
  fullWidth: true,
  id: '',
  videoAutoMute: true,
  videoCoverImage: { mediaType: '', urlPath: '' },
  videoDescription: '',
};

VideoBlock.propTypes = {
  fullWidth: PropTypes.bool,
  videoAutoPlay: PropTypes.bool.isRequired,
  videoCoverImage: PropTypes.shape({
    mediaType: PropTypes.string,
    urlPath: PropTypes.string,
  }),
  videoDescription: PropTypes.string,
  video: PropTypes.shape({
    coverImage: PropTypes.string,
    mediaType: PropTypes.string.isRequired,
    urlPath: PropTypes.string.isRequired,
  }).isRequired,
  id: PropTypes.string,
  videoAutoMute: PropTypes.bool,
  displayOnMobile: PropTypes.bool,
  displayOnDesktop: PropTypes.bool,
};

VideoBlockRoot.propTypes = {
  fullWidth: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};
