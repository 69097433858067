import styled from 'styled-components';
import { media } from '../../styles/mixins';

export const HeroImageSwiper = styled.div`
  --swiper-pagination-bullet-size: 0.75rem;
  --swiper-theme-color: #fff;
  --swiper-pagination-bullet-inactive-opacity: 1;
  --swiper-pagination-bullet-inactive-color: transparent;
  --swiper-navigation-size: 2rem;
  .swiper-pagination-bullet {
    border: 1px solid #fff;
  }
  .swiper-button-prev,
  .swiper-button-next {
    display: none;
  }
  .swiper-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-bullets.swiper-pagination-horizontal,
  .swiper-pagination-custom,
  .swiper-pagination-fraction {
    bottom: 20px;
  }
  .swiper-slide {
    -webkit-backface-visibility: hidden;
    -webkit-transform: translateZ(0);
    height: auto;
    display: grid;
  }
  .swiper-wrapper {
    -webkit-transform-style: preserve-3d;
    -webkit-transform: translateZ(0);
  }
  ${media.M`
		.swiper-button-prev, .swiper-button-next {
			display: block;
		}
    .swiper-horizontal > .swiper-pagination-bullets,
    .swiper-pagination-bullets.swiper-pagination-horizontal,
    .swiper-pagination-custom,
    .swiper-pagination-fraction {
      bottom: 50px;
    }
  `};
`;
