import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash/fp';
import { Conditional } from '../../components/Conditional/Conditional';
import { VideoBackground } from '../../components/VideoBackground/VideoBackground';
import { MagicButtons } from '../../components/MagicButtons/MagicButtons';
import { RocketImage } from '../../components/RocketImage/RocketImage';

import {
  MagicBlockContainer,
  InnerContainer,
  LeftImageArea,
  RightArea,
  Heading,
  MainTitle,
  MainText,
  BackgroundContainer,
} from './styles';
import { connect } from '../../store/store';

const MagicBlockComponent = (props) => {
  const {
    isMobile,
    isFirstBlock,
    hasFirstHeading,
    buttonColor,
    headingTextColor,
    subHeadingTextColor,
    bodyTextColor,
    backgroundImage: backgroundImageData,
    backgroundVideo: backgroundVideoData,
    displayOnDesktop,
    displayOnMobile,
  } = props;
  const videoUrl = get(['video', 0, 'urlPath'], props);
  const foregroundVideo = videoUrl && !isMobile;

  const backgroundImage = backgroundImageData?.urlPath;
  const backgroundVideo = backgroundVideoData?.urlPath;

  return (
    <MagicBlockContainer
      noMargin
      data-litium-block-id={get('draftId', props)}
      background={backgroundImage || backgroundVideo}
      className={backgroundImage || backgroundVideo ? 'background' : ''}
      displayOnDesktop={displayOnDesktop}
      displayOnMobile={displayOnMobile}
    >
      <InnerContainer imageRight={!get('moveToLeft', props)}>
        <LeftImageArea
          imageRight={!get('moveToLeft', props)}
          mediaOnHeight={!foregroundVideo}
        >
          <Conditional
            show={!foregroundVideo && get(['images'], props) !== null}
          >
            <RocketImage
              src={get(['images', 0, 'urlPath'], props)}
              alt={get(['images', 0, 'alt'], props)}
              heightRatio={1.5}
              // fractionOfDeviceWidth={0.5}
              loadInstantly={isFirstBlock}
              sizes="640px"
            />
          </Conditional>
          <Conditional show={foregroundVideo}>
            <VideoBackground
              aspectRatio={150}
              videosrc={videoUrl}
              videoposterimage={get(['images', 0, 'urlPath'], props)}
            />
          </Conditional>
        </LeftImageArea>
        <RightArea imageRight={!get('moveToLeft', props)}>
          <Heading color={subHeadingTextColor}>
            {get('subHeading', props)}
          </Heading>
          <MainTitle
            as={hasFirstHeading ? 'h1' : 'h2'}
            color={headingTextColor}
          >
            {get('title', props)}
          </MainTitle>
          <MainText
            dangerouslySetInnerHTML={{ __html: get('body', props) }}
            color={bodyTextColor}
          />
          <MagicButtons
            buttons={get('buttonCollection', props)}
            buttonColor={buttonColor}
          />
        </RightArea>
      </InnerContainer>
      {(backgroundImage || backgroundVideo) && (
        <BackgroundContainer>
          {(!backgroundVideo || isMobile) && (
            <RocketImage
              src={backgroundImage}
              heightRatio={undefined}
              fractionOfDeviceWidth={undefined}
              loadInstantly={isFirstBlock}
            />
          )}
          {backgroundVideo && !isMobile && (
            <VideoBackground
              aspectRatio={undefined}
              videosrc={backgroundVideo}
              videoposterimage={backgroundImage}
            />
          )}
        </BackgroundContainer>
      )}
    </MagicBlockContainer>
  );
};

MagicBlockComponent.defaultProps = {
  isMobile: false,
  isFirstBlock: false,
  hasFirstHeading: false,
  buttonColor: null,
  headingTextColor: null,
  subHeadingTextColor: null,
  bodyTextColor: null,
  backgroundImage: null,
  backgroundVideo: null,
  displayOnDesktop: true,
  displayOnMobile: true,
};

MagicBlockComponent.propTypes = {
  isMobile: PropTypes.bool,
  isFirstBlock: PropTypes.bool,
  hasFirstHeading: PropTypes.bool,
  buttonColor: PropTypes.string,
  headingTextColor: PropTypes.string,
  subHeadingTextColor: PropTypes.string,
  bodyTextColor: PropTypes.string,
  backgroundImage: PropTypes.object,
  backgroundVideo: PropTypes.object,
  displayOnMobile: PropTypes.bool,
  displayOnDesktop: PropTypes.bool,
};

export const MagicBlock = connect(MagicBlockComponent, {}, (store) => ({
  isMobile: get('viewState.isMobile', store),
}));
