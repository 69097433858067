import styled from 'styled-components';
import { ContainerRoot } from '../Container/styles';

export const Container = styled(ContainerRoot)`
  .depict.plp.category {
    h1.title {
      display: none;
    }
  }
`;
