import { createSelector } from 'reselect';

const setNewOptions = (options, products, productKeyToFilter) => {
  const newOptions = options.map(option => {
    const matchingProducts = products.filter(product => {
      if (product[productKeyToFilter]) {
        const matchingItems = product[productKeyToFilter]?.filter(item => item.id === option.id);
        return matchingItems.length > 0;
      }

      return null;
    });

    return { ...option, productCount: matchingProducts.length };
  });

  return newOptions;
};

const setStockBalance = products => {
  let stockBalance = 0;

  products.forEach(product => {
    product.variants.forEach(variant => {
      variant.sizes.forEach(size => {
        stockBalance += size.stockBalance;
      });
    });
  });

  return stockBalance;
};

const setProductCountOnFilter = (products, filters) => {
  const newFilters = Object.values(filters)
    .map(filter => {
      if (!filter) return filter;

      if (filter.id === 'category') {
        const newOptions = filter.options.map(option => {
          const matchingProducts = products.filter(product => {
            const isMatching = product.category.title === option.title;
            return isMatching;
          });

          const stockBalance = setStockBalance(matchingProducts);

          return { ...option, productCount: matchingProducts.length, stockBalance };
        });

        return { ...filter, options: newOptions };
      }

      if (filter.id === 'color') {
        const newOptions = filter.options.map(option => {
          const colorCodes = option.data.map(data => data.id);

          const matchingProducts = products.filter(product => {
            const matchingColors = product.variants.filter(variant => colorCodes.includes(variant.color.id));
            return matchingColors.length > 0;
          });

          const stockBalance = setStockBalance(matchingProducts);

          return { ...option, productCount: matchingProducts.length, stockBalance };
        });

        return { ...filter, options: newOptions };
      }

      if (filter.id === 'size') {
        const newOptions = filter.options.map(option => {
          let stockBalance = 0;

          const matchingProducts = products.filter(product => {
            const matchingSizes = product.variants.filter(variant => {
              const sizesFound = variant.sizes.filter(size => {
                if (size.title !== option.id) return false;

                const { stockBalance: stock } = size;
                stockBalance += stock;
                return true;
              });
              return sizesFound.length > 0;
            });
            return matchingSizes.length > 0;
          });

          return { ...option, productCount: matchingProducts.length, stockBalance };
        });

        return { ...filter, options: newOptions };
      }

      if (filter.id === 'activity') {
        const newOptions = setNewOptions(filter.options, products, 'activities');
        return { ...filter, options: newOptions };
      }

      if (filter.id === 'clothingFunction') {
        const newOptions = setNewOptions(filter.options, products, 'function');
        return { ...filter, options: newOptions };
      }

      if (filter.id === 'sustainability') {
        const newOptions = setNewOptions(filter.options, products, 'sustainabilityOptions');
        return { ...filter, options: newOptions };
      }

      if (filter.id === 'gender') {
        const newOptions = setNewOptions(filter.options, products, 'gender');
        return { ...filter, options: newOptions };
      }

      return filter;
    })
    .reduce((acc, filter) => {
      if (!filter) return acc;
      acc[filter.id] = filter;
      return acc;
    }, {});

  return newFilters;
};

export const selectAvailableFilters = createSelector(setProductCountOnFilter, filters => {
  return filters;
});
