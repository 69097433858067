import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import qs from 'qs';
import {
  compose,
  size as _size,
  getOr,
  map,
  get,
  merge,
  flow,
  entries,
  isEmpty,
  reduce,
  isEqual,
} from 'lodash/fp';
import { withRouter } from 'next/router';
import { connect } from '../../store/store';
import { setViewstate, removeViewstate } from '../../store/actions/actions';
import { FilterTagsHeight } from '../../styles/animations';
import { Icon } from '../Icon/Icon';
import { ICONS } from '../../constants/icons';
import { MOBILE_FILTERS } from '../../constants/modals';
import { AccordionItem } from '../Accordion/AccordionItem';
import { renderSustainabilitySvg } from '../../helpers/renderSustainabilitySvg';
import { selectAvailableFilters } from '../../store/selectors/selectAvailableFilters';
import {
  FilterListItem,
  FilterOptionBtn,
  DropDownArea,
  CheckBox,
  CheckBoxLabel,
  TextLabel,
  TextSoldOut,
  ChoiceText,
  Circle,
  ColorCircleList,
  ColorItem,
  Tag,
  SustIcon,
} from './styles';
import { ProductMobileFilters } from './ProductMobileFilters';
import { ProductDesktopFilters } from './ProductDesktopFilters';

class ProductFiltersComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeFilterOption: -1,
    };

    this.dropdownRef = React.createRef();
    this.setActiveFilterOptions = this.setActiveFilterOptions.bind(this);
    this.removeFilter = this.removeFilter.bind(this);
    this.closeModals = this.closeModals.bind(this);
    this.renderFilterTagItems = this.renderFilterTagItems.bind(this);
    this.renderFilterTags = this.renderFilterTags.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.escFunction = this.escFunction.bind(this);
    this.handleShowModal = this.handleShowModal.bind(this);
  }

  componentDidMount() {
    this.ismounted = true;
    document.addEventListener('mousedown', this.handleClick, false);
    document.addEventListener('keydown', this.escFunction, false);
  }

  componentDidUpdate(prevProps) {
    const {
      router,
      router: { query },
      viewstate,
    } = this.props;
    const filtersHaveChanged = !isEqual(
      prevProps.viewstate.filter,
      viewstate.filter
    );
    const activeFilters = get('filter', viewstate);

    // If filters change, update query params
    if (filtersHaveChanged && activeFilters) {
      // Create object with active filters
      const activeFiltersValues = Object.keys(activeFilters).reduce(
        (acc, filterKey) => {
          const filterValues = Object.keys(activeFilters[filterKey]);
          const nonEmptyValues = filterValues.filter((value) => value.length);

          if (nonEmptyValues.length > 0) {
            acc[filterKey] = nonEmptyValues;
          }

          return acc;
        },
        {}
      );

      // Update url with filers as query params on the 'filter' property
      router.push(
        {
          pathname: router.pathname,
          query: { ...query, filter: activeFiltersValues },
        },
        `/${query.market}/${query.parentId ? `${query.parentId}/` : ''}${
          query.id
        }?${qs.stringify(
          {
            filter: activeFiltersValues,
          },
          { encode: false, arrayFormat: 'comma' }
        )}`,
        { shallow: true }
      );
    } else if (filtersHaveChanged && !activeFilters) {
      router.push(
        {
          pathname: router.pathname,
          query: { ...query },
        },
        `/${query.market}/${query.parentId ? `${query.parentId}/` : ''}${
          query.id
        }`,
        { shallow: true }
      );
    }
  }

  componentWillUnmount() {
    this.ismounted = false;
    document.removeEventListener('mousedown', this.handleClick, false);
    document.removeEventListener('keydown', this.escFunction, false);
  }

  setActiveFilterOptions(index) {
    if (this.ismounted) {
      this.setState((state) => ({
        activeFilterOption: state.activeFilterOption === index ? -1 : index,
      }));
    }
  }

  handleShowModal() {
    if (get(['viewstate', 'activeModal'], this.props) === MOBILE_FILTERS) {
      this.props.removeViewstate('activeModal');
    } else {
      this.props.setViewstate('activeModal', MOBILE_FILTERS);
    }
  }

  handleClick(e) {
    const dropdownElement = this.dropdownRef.current;
    if (
      (!dropdownElement || !dropdownElement.contains(e.target)) &&
      this.ismounted
    ) {
      // close dropdowns when clicking outside dropdown area
      this.setState({ activeFilterOption: -1 });
    }
  }

  escFunction(event) {
    if (event.keyCode === 27) {
      // close dropdowns when pressing esc
      this.setState({ activeFilterOption: -1 });
    }
  }

  updateFilter(key, event) {
    const { value, dataset } = event.target;
    const { filterTitle, optionTitle } = dataset;
    const myObj = { [value]: { filterTitle, optionTitle } };
    const currentState = getOr([], ['filter', key], this.props.viewstate);

    if (
      Object.keys(getOr([], ['filter', key], this.props.viewstate)).includes(
        value
      )
    ) {
      this.props.removeViewstate(['filter', key, value]);
    } else {
      this.props.setViewstate(['filter', key], merge(myObj, currentState));
    }
  }

  removeFilter(path) {
    this.props.removeViewstate(path);
  }

  closeModals() {
    this.props.removeViewstate('activeModal');
  }

  hasAnyFilters() {
    let sumOfAllSelectedFilterOptions = 0;
    if (this.props.viewstate.filter) {
      sumOfAllSelectedFilterOptions = reduce(
        (sum, n) => sum + _size(n),
        0,
        this.props.viewstate.filter
      );
      return sumOfAllSelectedFilterOptions > 0;
    }
    return false;
  }

  renderCheckboxes(title, key, data, mobile) {
    const { websiteStrings } = this.props;
    const visibleItems = data.filter(
      (item) => item.productCount === undefined || item.productCount > 0
    );

    if (visibleItems.length < 1) {
      return (
        <li>
          <TextLabel>
            <ChoiceText>
              {getOr(
                'No alternatives found',
                'filterNoChoicesFound',
                websiteStrings
              )}
            </ChoiceText>
          </TextLabel>
        </li>
      );
    }

    return visibleItems.map((item) => {
      const soldOut = item.stockBalance !== undefined && item.stockBalance < 1;
      const hideItem = item.productCount !== undefined && item.productCount < 1;

      if (hideItem) return null;

      return (
        <li key={item.id}>
          <CheckBox
            id={item.id}
            data-filter-title={title}
            data-option-title={item.title}
            value={item.id}
            checked={Object.keys(
              getOr([], ['filter', key], this.props.viewstate)
            ).includes(item.id)}
            onChange={(event) =>
              soldOut ? undefined : this.updateFilter(key, event)
            }
            size={16}
            spacing={8}
            mobile={mobile}
            disabled={soldOut}
          />
          <CheckBoxLabel htmlFor={item.id} mobile={mobile} />
          <TextLabel htmlFor={item.id} mobile={mobile} soldOut={soldOut}>
            <ChoiceText>{item.title}</ChoiceText>
          </TextLabel>
          {soldOut ? (
            <TextSoldOut soldOut noFlex>
              {getOr('Sold out', 'filterSoldOut', websiteStrings)}
            </TextSoldOut>
          ) : (
            <>
              <SustIcon>{renderSustainabilitySvg(item.id)}</SustIcon>
              {_size(item.data) ? (
                <ColorCircleList>
                  {map(
                    (color) => (
                      <ColorItem key={color.id}>
                        <Circle color={color.id} />
                      </ColorItem>
                    ),
                    item.data
                  )}
                </ColorCircleList>
              ) : (
                false
              )}
            </>
          )}
        </li>
      );
    });
  }

  renderAllFilters() {
    let sumOfAllSelectedFilterOptions = 0;
    if (this.props.viewstate.filter) {
      sumOfAllSelectedFilterOptions = reduce(
        (sum, n) => sum + _size(n),
        0,
        this.props.viewstate.filter
      );

      return sumOfAllSelectedFilterOptions > 0 ? (
        <span>({sumOfAllSelectedFilterOptions})</span>
      ) : null;
    }
    return null;
  }

  renderTitle(filter, sizeItem) {
    return sizeItem < 1 ? (
      filter
    ) : (
      <Fragment>
        {filter} <span>({sizeItem})</span>
      </Fragment>
    );
  }

  renderFilterOption(title, key, data) {
    const { viewstate } = this.props;
    const { isMobile } = viewstate;

    if (isEmpty(data)) return null;

    const renderFilterItems = this.renderCheckboxes(title, key, data, isMobile);
    if (isMobile) {
      return (
        <AccordionItem
          key={key}
          title={this.renderTitle(
            title,
            this.props.viewstate.filter
              ? _size(this.props.viewstate.filter[key])
              : -1
          )}
          bgColorWhite
        >
          <DropDownArea mobile items={_size(data)}>
            {renderFilterItems}
          </DropDownArea>
        </AccordionItem>
      );
    }
    return (
      <FilterListItem active={this.state.activeFilterOption === key} key={key}>
        <FilterOptionBtn onClick={() => this.setActiveFilterOptions(key)}>
          {title}
          <Icon icon={ICONS.ARROWDOWN} width={15} height={8} />
        </FilterOptionBtn>
        <DropDownArea
          active={this.state.activeFilterOption === key}
          items={_size(data)}
        >
          {renderFilterItems}
        </DropDownArea>
      </FilterListItem>
    );
  }

  renderFilterTagItems(entry) {
    const key = entry[0];
    const data = entry[1];

    return flow(
      entries,
      map((activeEntry) => {
        const activeKey = activeEntry[0];
        const activeData = activeEntry[1];
        return (
          <FilterTagsHeight key={activeData.optionTitle} timeout={300}>
            <Tag
              onClick={() => this.removeFilter(['filter', key, activeKey])}
              as="div"
            >
              {activeData.filterTitle}: {activeData.optionTitle}
              <Icon icon={ICONS.CLOSE} size={8} />
            </Tag>
          </FilterTagsHeight>
        );
      })
    )(data);
  }

  renderFilterTags() {
    return flow(
      entries,
      map((value) => this.renderFilterTagItems(value))
    )(getOr([], ['viewstate', 'filter'], this.props));
  }

  render() {
    const { viewstate, filteredProducts } = this.props;
    const {
      color,
      size,
      category,
      gender,
      clothingFunction,
      activity,
      sustainability,
    } = this.props.filtersWithAvailability;
    const { isMobile, activeModal } = viewstate;

    const allColors = this.renderFilterOption(
      color?.title,
      'color',
      color?.options
    );
    const allSizes = this.renderFilterOption(
      size?.title,
      'size',
      size?.options
    );
    const allActivities = this.renderFilterOption(
      activity?.title,
      'activity',
      activity?.options
    );
    const allFunctions = this.renderFilterOption(
      clothingFunction?.title,
      'function',
      clothingFunction?.options
    );
    const allGenders = this.renderFilterOption(
      gender?.title,
      'gender',
      gender?.options
    );
    const allCategories = this.renderFilterOption(
      category?.title,
      'category',
      category?.options
    );
    const allSustainabilityOptions = this.renderFilterOption(
      sustainability?.title,
      'sustainabilityOption',
      sustainability?.options
    );

    if (isMobile) {
      const renderNumOfAllFilters = this.renderAllFilters();
      const hasFilters = this.hasAnyFilters();
      const accordionChildren = [
        allGenders,
        allCategories,
        allColors,
        allSizes,
        allActivities,
        allFunctions,
        allSustainabilityOptions,
      ];
      return (
        <ProductMobileFilters
          handleShowModal={this.handleShowModal}
          showModal={activeModal === MOBILE_FILTERS}
          renderNumOfAllFilters={renderNumOfAllFilters}
          filteredProducts={filteredProducts}
          accordionChildren={accordionChildren}
          removeFilter={() => this.removeFilter(['filter'])}
          closeModals={this.closeModals}
          hasFilters={hasFilters}
        />
      );
    }

    const activeFilterTags = this.renderFilterTags();

    const allFilters = [
      allGenders,
      allCategories,
      allColors,
      allSizes,
      allActivities,
      allFunctions,
      allSustainabilityOptions,
    ];
    return (
      <ProductDesktopFilters
        dropdownRef={this.dropdownRef}
        allFilters={allFilters}
        filteredProducts={filteredProducts}
        activeFilterTags={activeFilterTags}
        removeFilter={() => this.removeFilter(['filter'])}
      />
    );
  }
}

ProductFiltersComponent.defaultProps = {
  viewstate: {},
  filteredProducts: [],
  // filters: {},
  filtersWithAvailability: {},
  websiteStrings: {},
};

ProductFiltersComponent.propTypes = {
  router: PropTypes.object.isRequired,
  setViewstate: PropTypes.func.isRequired,
  removeViewstate: PropTypes.func.isRequired,
  viewstate: PropTypes.object,
  filteredProducts: PropTypes.array,
  // filters: PropTypes.object,
  filtersWithAvailability: PropTypes.object,
  websiteStrings: PropTypes.object,
};

export const ProductFilters = compose(withRouter, connect)(
  ProductFiltersComponent,
  { setViewstate, removeViewstate },
  (store, props) => ({
    viewstate: store.viewState,
    filtersWithAvailability: selectAvailableFilters(
      props.filteredProducts,
      props.filters
    ),
    websiteStrings: get(['bootstrap', 'data', 'websiteStrings'], store),
  })
);
