import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { HeroTextBlockContainer, HeroTextBlockTitle, HeroTextBlockDescription, HeroTextBlockLabel } from './styles';
import { Conditional } from '../../components/Conditional/Conditional';
import { MagicButtons } from '../../components/MagicButtons/MagicButtons';

export const HeroTextBlock = props => {
  const {
    draftId,
    hasFirstHeading,
    title,
    subHeading,
    body,
    displayBodyOnMobile,
    buttonCollection,
    buttonColor,
    displayOnDesktop,
    displayOnMobile
  } = props;
  const isMobile = useSelector(store => store?.viewState?.isMobile);

  return (
    <HeroTextBlockContainer
      data-litium-block-id={draftId}
      displayOnDesktop={displayOnDesktop}
      displayOnMobile={displayOnMobile}
    >
      <Conditional show={!!subHeading}>
        <HeroTextBlockLabel>{subHeading}</HeroTextBlockLabel>
      </Conditional>

      <Conditional show={!!title}>
        <HeroTextBlockTitle as={hasFirstHeading ? 'h1' : 'h2'}>{title}</HeroTextBlockTitle>
      </Conditional>

      <Conditional show={!!body && (!isMobile || displayBodyOnMobile)}>
        <HeroTextBlockDescription dangerouslySetInnerHTML={{ __html: body }} />
      </Conditional>

      <Conditional show={!!buttonCollection}>
        <MagicButtons as="a" buttons={buttonCollection} centerButtons buttonColor={buttonColor} />
      </Conditional>
    </HeroTextBlockContainer>
  );
};

HeroTextBlock.defaultProps = {
  draftId: undefined,
  hasFirstHeading: false,
  title: '',
  subHeading: '',
  body: '',
  displayBodyOnMobile: false,
  buttonCollection: [],
  buttonColor: null,
  displayOnDesktop: true,
  displayOnMobile: true
};

HeroTextBlock.propTypes = {
  draftId: PropTypes.string,
  hasFirstHeading: PropTypes.bool,
  title: PropTypes.string,
  subHeading: PropTypes.string,
  body: PropTypes.string,
  displayBodyOnMobile: PropTypes.bool,
  buttonCollection: PropTypes.array,
  buttonColor: PropTypes.string,
  displayOnMobile: PropTypes.bool,
  displayOnDesktop: PropTypes.bool
};
