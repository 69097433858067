import { useEffect, useState } from 'react';

export const useCalculateColumnSpan = () => {
  const mobileMediaQuery = '(max-width:831px)';
  const tabletMediaQuery = '(max-width:1024px)';

  if (typeof window === 'undefined') return 4;

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [columnSpan, setColumnSpan] = useState(
    window.matchMedia(mobileMediaQuery).matches
      ? 2
      : window.matchMedia(tabletMediaQuery).matches
      ? 3
      : 4
  );

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    const mobileMediaQuery = window.matchMedia('(max-width:831px)');
    const tabletMediaQuery = window.matchMedia('(max-width:1024px)');
    const handleChange = () =>
      setColumnSpan(
        window.matchMedia('(max-width:831px)').matches
          ? 2
          : window.matchMedia('(max-width:1024px)').matches
          ? 3
          : 4
      );

    mobileMediaQuery.addEventListener('change', handleChange);
    tabletMediaQuery.addEventListener('change', handleChange);

    return () => {
      mobileMediaQuery.removeEventListener('change', handleChange);
      tabletMediaQuery.removeEventListener('change', handleChange);
    };
  }, []);

  if (typeof window === 'undefined') return false;

  return columnSpan;
};
