import { get } from 'lodash/fp';
import { format, isValid } from 'date-fns';
import enLocale from 'date-fns/locale/en-US';
import svLocale from 'date-fns/locale/sv';
import deLocale from 'date-fns/locale/de';

const languages = withTime => {
  return {
    de: {
      locale: deLocale,
      format: `MMM d, yyyy${withTime ? ' HH:mm' : ''}`
    },
    en: {
      locale: enLocale,
      format: `MMM d, yyyy${withTime ? ' HH:mm' : ''}`
    },
    sv: {
      locale: svLocale,
      format: `d MMM yyyy${withTime ? ' HH:mm' : ''}`
    }
  };
};

export const formatDate = (date, language = 'en', withTime = false) => {
  const dateObject = new Date(date);
  const activeLocale = languages(withTime)[language] || get('en', languages);
  return isValid(dateObject)
    ? format(dateObject, get('format', activeLocale), { locale: get('locale', activeLocale) })
    : null;
};
