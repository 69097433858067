import styled, { css } from 'styled-components';
import { fonts } from '../../styles/typography';
import { media, dynamicFontSize } from '../../styles/mixins';

export const MainHeading = styled.h1`
  ${fonts.NeoSansLight};
  ${dynamicFontSize(40, 60)};
  margin: 0.5em 0 0.75em;
  font-stretch: normal;
  text-align: center;
  line-height: 1.14;
  letter-spacing: -0.2px;
  color: #000000;

  ${props =>
    typeof props.mt !== 'undefined' &&
    css`
      margin-top: ${props.mt}px !important;
    `};

  &:after {
    content: '';
    display: block;
    margin: 12px auto 10px;
    height: 1px;
    width: 120px;
    background-color: black;
  }
`;

export const MainHeadingText = styled.div`
  ${fonts.GoudyOldStyleRegular};
  font-size: 16px;
  line-height: 24px;
  text-align: center;
`;

export const MainHeadingButton = styled.button`
  ${fonts.GoudyOldStyleRegular};
  font-size: 16px;
  line-height: 24px;
  background: none;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  transition: opacity 0.1s ease-in-out;

  &:hover {
    opacity: 0.45;
  }
`;

export const MainHeadingContainer = styled.div`
  max-width: 470px;
  margin: 0 auto 20px;
  text-align: center;

  ${media.M`
    margin: 0 auto 50px;
  `};

  ${({ expanded }) =>
    expanded &&
    css`
      max-width: 903px;
    `};
`;
