import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { size, flatten, map } from 'lodash/fp';
import { String } from '../String/String';
import {
  OuterContainer,
  OuterFilterList,
  Tag,
  TagContainer,
  Tags,
} from './styles';
import { FilterTagsHeight } from '../../styles/animations';

export const ProductDesktopFilters = ({
  dropdownRef,
  allFilters,
  activeFilterTags,
  removeFilter,
}) => {
  const ref = React.useRef();

  return (
    <Fragment>
      <OuterContainer>
        <OuterFilterList ref={dropdownRef}>
          {map((filter) => filter, allFilters)}
        </OuterFilterList>
      </OuterContainer>

      <TagContainer>
        <Tags>
          {activeFilterTags}
          {size(flatten(activeFilterTags)) ? (
            <FilterTagsHeight timeout={300} nodeRef={ref}>
              <Tag clear onClick={removeFilter} as="div">
                <String id="filterClearAllLabel" />
              </Tag>
            </FilterTagsHeight>
          ) : (
            false
          )}
        </Tags>
      </TagContainer>
    </Fragment>
  );
};

ProductDesktopFilters.defaultProps = {
  dropdownRef: {},
  activeFilterTags: [],
  allFilters: [],
};

ProductDesktopFilters.propTypes = {
  dropdownRef: PropTypes.object,
  activeFilterTags: PropTypes.array,
  allFilters: PropTypes.array,
  removeFilter: PropTypes.func.isRequired,
};
