import styled, { css } from 'styled-components';
import { dynamicPadding, media, hover } from '../../styles/mixins';
import { RocketImage } from '../../components/RocketImage/RocketImage';
import { fonts } from '../../styles/typography';

export const PreambleContainer = styled.div`
  margin: 40px auto;
  max-width: 750px;
  box-sizing: content-box;
`;

export const FeaturedProductsHeading = styled.h2`
  ${fonts.NeoSansRegular};
  font-size: 25px;
  font-stretch: normal;
  line-height: 1.28;
  letter-spacing: normal;
  color: #000000;
  margin-bottom: 0;
  + div p:first-child {
    margin-top: 10px;
  }
`;

export const ProductAndImageContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${props =>
    props.mobileImageBelowProducts &&
    css`
      flex-direction: column-reverse;
    `};

  ${media.M`
    align-items: center;
    margin: 40px 0 30px;
    flex-direction: row-reverse;
    
    ${props =>
      props.imageToTheLeft &&
      css`
        flex-direction: row;
      `}
  `};

  ${dynamicPadding(0, 60, 1160, 1440)};
`;

export const ImageContainer = styled.div`
  ${media.M`
    margin: auto 0 auto 30px;
    width: 45%;
  `};
  ${media.L`
    margin: auto 0 auto 80px;
    width: 45%;
  `};
  ${media.XL`
    margin: auto 0 auto 80px;
    width: 41%;
  `};

  ${props =>
    props.imageToTheLeft &&
    css`
      ${media.M`
      margin: auto 30px auto 0;      
    `};

      ${media.L`
      margin: auto 80px auto 0;      
    `};
    `};
`;

export const FeaturedProductsBlockImage = styled(RocketImage)`
  width: 100%;
`;

export const ProductListContainer = styled.div`
  max-width: 100%;
  ${media.M`
    margin: auto 0;
    width: 55%;
  `};
`;

export const FeaturedProductButtonContainer = styled.div`
  text-align: center;
`;

export const FeatureProductsBlockButton = styled.button`
  ${fonts.NeoSansRegular};
  font-size: 14px;
  text-decoration: none;
  min-width: 160px;
  width: auto;
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1.125rem;
  color: ${props => props.theme.color.white};
  border: 1px solid ${props => props.theme.color.white};
  background: transparent;
  cursor: pointer;
  transition: background-color 0.25s ease 0s, color 0.25s ease 0s;

  &:not(:last-child) {
    margin-bottom: 10px;
  }

  ${hover`
    color: ${props => props.theme.color.black};
    background-color: ${props => props.theme.color.white};
  `};

  ${({ theme, isBlack }) =>
    isBlack &&
    css`
      color: ${theme.color.white};
      background: ${theme.color.black};
      border-color: ${theme.color.black};

      &:hover {
        border-color: ${theme.color.white};
      }
    `};

  ${({ theme, isWhite }) =>
    isWhite &&
    css`
      color: ${theme.color.black};
      background: ${theme.color.white};

      &:hover {
        color: ${theme.color.white};
        background: ${theme.color.black};
        border-color: ${theme.color.black};
      }
    `};

  ${({ theme, whiteBorder }) =>
    whiteBorder &&
    css`
      color: ${theme.color.white};
      background: transparent;
      border-color: ${theme.color.white};

      &:hover {
        color: ${theme.color.black};
        background: ${theme.color.white};
        border-color: ${theme.color.white};
      }
    `};

  ${({ theme, blackBorder }) =>
    blackBorder &&
    css`
      color: ${theme.color.black};
      border: 1px solid ${theme.color.black};
    `}

  ${media.HBBP`
    display: inline-flex;
    
    &:not(:last-child) {
      margin-bottom: 0;
      margin-right: 15px;
    }
  `};

  ${media.IE`
    height: 50px;
  `};
`;
