import styled, { css } from 'styled-components';
import { fonts } from '../../styles/typography';
import { media } from '../../styles/mixins';
import { ContainerRoot, FullWidthContainer } from '../../components/Container/styles';

export const Heading = styled.span`
  ${fonts.GoudyOldStyleItalic};
  font-size: 18px;
  color: ${props => props.theme.color.black};
  ${props =>
    props.color === 'White' &&
    css`
      color: ${props2 => props2.theme.color.white};
    `};
  ${props =>
    props.color === 'Brown' &&
    css`
      color: ${props2 => props2.theme.color.darkSepia};
    `};
`;

export const MainTitle = styled.h2`
  ${fonts.NeoSansRegular};
  font-size: 40px;
  color: ${props => props.theme.color.darkSepia};
  margin: 10px 0 20px;
  text-align: center;
  max-width: 400px;
  width: 100%;
  ${media.M`
    margin: 10px 0 35px;
    text-align: left;
    max-width: none;
  `};
  ${props =>
    props.color === 'Black' &&
    css`
      color: ${props2 => props2.theme.color.black};
    `};
  ${props =>
    props.color === 'White' &&
    css`
      color: ${props2 => props2.theme.color.white};
    `};
`;

export const MainText = styled.div`
  width: 100%;
  margin: 0 0 32px;
  color: ${props => props.theme.color.black};
  text-align: center;
  ${props =>
    props.color === 'White' &&
    css`
      color: ${props2 => props2.theme.color.white};
    `};
  ${props =>
    props.color === 'Brown' &&
    css`
      color: ${props2 => props2.theme.color.darkSepia};
    `};
  ${media.M`
    text-align: left;
  `};
  &:last-child {
    margin: 0;
  }
  p {
    ${fonts.GoudyOldStyleRegular};
    font-size: 16px;
    line-height: 1.5;
    margin-top: 0;
    margin-bottom: 0;
    width: 100%;
  }
`;

export const MagicBlockContainer = styled(FullWidthContainer)`
  background-color: ${props => props.theme.color.primary};
  padding-top: 40px;
  padding-bottom: 80px;

  ${media.S`
    padding-top: 80px;
    padding-bottom: 80px;
  `};

  &:not(.background) {
    + [class*='styles__MagicBlockContainer'] {
      &:not(.background) {
        padding-top: 0;
      }
    }
  }

  ${props =>
    props.background &&
    css`
      position: relative;
      z-index: 1;
    `};
`;

export const InnerContainer = styled(ContainerRoot)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0;
  ${media.M`
    margin: 0 auto;
  `};
  ${media.M`
    flex-direction: row;
    ${props =>
      props.imageRight &&
      css`
        flex-direction: row-reverse;
      `};
  `};
`;

export const LeftImageArea = styled.div`
  position: relative;
  margin-right: 0;
  padding-right: 0;
  width: 100%;
  flex: 1;
  box-sizing: content-box;
  max-width: 500px;

  @media (min-width: 1024px) and (max-height: 700px) {
    width: auto;
  }
  @media (min-width: 1024px) and (max-height: 500px) {
    width: auto;
  }

  ${media.M`
    max-width: 400px;
  `};

  ${media.M`
    margin-right: 20px;
    padding-right: 40px;
    ${props =>
      props.imageRight &&
      css`
        margin-right: 0 !important;
        padding-right: 0 !important;
      `};
  `};

  ${media.L`
    margin-right: 20px;
    padding-right: 60px;
  `};
`;

export const BackgroundContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
  > div {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: auto;
  }
`;

export const RightArea = styled.div`
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: none;
  margin-top: 40px;

  flex: 1;

  @media (min-width: 560px) {
    width: 500px;
  }
  ${media.M`
    align-items: flex-start;
    max-width: 400px;
    margin-top: 0;
    width: auto;
    ${props =>
      props.imageRight &&
      css`
        margin-right: 40px;
      `};
  `};

  ${media.IE`
    flex: 0 1 auto;
    width: 400px;
  `};
`;

export const MainImage = styled.div``;
