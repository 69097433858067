import React from 'react';
import dynamic from 'next/dynamic';
import PropTypes from 'prop-types';
import {
  EDITOR_BLOCK,
  FAQ_BLOCK,
  FEATURED_PRODUCTS_BLOCK,
  GROUPED_PRODUCT_BLOCK,
  HERO_IMAGE_BLOCK,
  HERO_TEXT_BLOCK,
  MAGIC_BLOCK,
  NEWSLETTER_BLOCK,
  PRODUCT_CATEGORY_LISTING_BLOCK,
  PRODUCT_LIST_LISTING_BLOCK,
  THREE_PUFF_BLOCK,
  GROUPED_PRODUCT_WITH_IMAGES_BLOCK,
  CTA_BLOCK,
  EDITORIAL_PAGE,
  VIDEO_BLOCK,
  HERO_SWIPE_BLOCK,
} from '../../constants/cmsTypes';

// Blocks
import { HeroImageBlock } from '../../blocks/HeroImageBlock/HeroImageBlock';
import { HeroTextBlock } from '../../blocks/HeroTextBlock/HeroTextBlock';
import { MagicBlock } from '../../blocks/MagicBlock/MagicBlock';
import { InformationBlock } from '../../blocks/InformationBlock/InformationBlock';
import { AccordionBlock } from '../../blocks/AccordionBlock/AccordionBlock';
import { EditorBlock } from '../../blocks/EditorBlock/EditorBlock';
import { NewsletterBlock } from '../../blocks/NewsletterBlock/NewsletterBlock';
import ProductCategoryListingBlock from '../../blocks/ProductCategoryListingBlock/ProductCategoryListingBlock';
import { ProductListListingBlock } from '../../blocks/ProductListListingBlock/ProductListListingBlock';
import { GroupedProductWithImageBlock } from '../../blocks/GroupedProductWithImageBlock/GroupedProductWithImageBlock';
import { VideoBlock } from '../../blocks/VideoBlock/VideoBlock';
import { CTABlock } from '../../blocks/CTABlock/CTABlock';
import { HeroSwipeBlock } from '../../blocks/HeroSwipeBlock/HeroSwipeBlock';

const GroupedProductBlock = dynamic(
  () =>
    import('../../blocks/GroupedProductBlock/GroupedProductBlock').then(
      (mod) => mod.GroupedProductBlock
    ),
  { loading: () => <div /> }
);
const FeaturedProductsBlock = dynamic(
  () =>
    import('../../blocks/FeaturedProductsBlock/FeaturedProductsBlock').then(
      (mod) => mod.FeaturedProductsBlock
    ),
  { loading: () => <div /> }
);

const BlockListComponent = (props) => {
  if (!props.data) return false;

  const { data, hasMainHeading, pageType, currentPage } = props;
  const firstTitleIndex = data.findIndex((block) => block.title);

  const blocks = props.data.map((block, index) => {
    const { type } = block;
    const key = `${type}-${index}`;
    const isFirstBlock = index === 0;
    const hasFirstHeading = index === firstTitleIndex && hasMainHeading;
    const previousType = data[index - 1]?.type;
    const centerFaq =
      previousType === EDITOR_BLOCK ||
      previousType === HERO_IMAGE_BLOCK ||
      pageType === EDITORIAL_PAGE;

    switch (type) {
      case HERO_IMAGE_BLOCK:
        return (
          <HeroImageBlock
            key={key}
            {...block}
            isFirstBlock={isFirstBlock}
            hasFirstHeading={hasFirstHeading}
          />
        );
      case HERO_TEXT_BLOCK:
        return (
          <HeroTextBlock
            key={key}
            {...block}
            hasFirstHeading={hasFirstHeading}
          />
        );
      case MAGIC_BLOCK:
        return (
          <MagicBlock
            key={key}
            {...block}
            isFirstBlock={isFirstBlock}
            hasFirstHeading={hasFirstHeading}
          />
        );
      case GROUPED_PRODUCT_BLOCK:
        return (
          <GroupedProductBlock
            key={key}
            {...block}
            isFirstBlock={isFirstBlock}
            hasFirstHeading={hasFirstHeading}
          />
        );
      case THREE_PUFF_BLOCK:
        return (
          <InformationBlock
            key={key}
            {...block}
            isFirstBlock={isFirstBlock}
            hasFirstHeading={hasFirstHeading}
          />
        );
      case FEATURED_PRODUCTS_BLOCK:
        return (
          <FeaturedProductsBlock
            key={key}
            {...block}
            isFirstBlock={isFirstBlock}
            hasFirstHeading={hasFirstHeading}
          />
        );
      case FAQ_BLOCK:
        return (
          <AccordionBlock
            key={key}
            {...block}
            isFirstBlock={isFirstBlock}
            hasFirstHeading={hasFirstHeading}
            centerFaq={centerFaq}
          />
        );
      case EDITOR_BLOCK:
        return <EditorBlock key={key} {...block} isFirstBlock={isFirstBlock} />;
      case NEWSLETTER_BLOCK:
        return (
          <NewsletterBlock key={key} {...block} isFirstBlock={isFirstBlock} />
        );
      case PRODUCT_CATEGORY_LISTING_BLOCK:
        return (
          <ProductCategoryListingBlock
            key={key}
            {...block}
            isFirstBlock={isFirstBlock}
            hasFirstHeading={hasFirstHeading}
            pageType={pageType}
            page={currentPage}
          />
        );
      case PRODUCT_LIST_LISTING_BLOCK:
        return (
          <ProductListListingBlock
            key={key}
            {...block}
            isFirstBlock={isFirstBlock}
            hasFirstHeading={hasFirstHeading}
            pageType={pageType}
          />
        );
      case GROUPED_PRODUCT_WITH_IMAGES_BLOCK:
        return (
          <GroupedProductWithImageBlock
            key={key}
            {...block}
            isFirstBlock={isFirstBlock}
            hasFirstHeading={hasFirstHeading}
            parentIndex={index}
          />
        );
      case CTA_BLOCK:
        return <CTABlock key={key} {...block} />;
      case VIDEO_BLOCK:
        return <VideoBlock key={key} id={key} {...block} />;
      case HERO_SWIPE_BLOCK:
        return <HeroSwipeBlock key={key} id={key} {...block} />;
      default:
        return false;
    }
  });

  return <>{blocks}</>;
};

BlockListComponent.defaultProps = {
  data: [],
  hasMainHeading: false,
  pageType: '',
  currentPage: {},
};

BlockListComponent.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
    })
  ),
  hasMainHeading: PropTypes.bool,
  pageType: PropTypes.string,
  currentPage: PropTypes.object,
};

export const BlockList = BlockListComponent;
