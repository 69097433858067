import styled from 'styled-components';
import { fonts } from '../../styles/typography';
import { media } from '../../styles/mixins';
import { ContainerRoot } from '../../components/Container/styles';
import { MainHeading } from '../../components/MainHeading/MainHeading';

export const HeroTextBlockContainer = styled(ContainerRoot)`
  transition: height 10000s steps(1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 46px;

  ${media.S`
    margin-top: 46px;
  `};
  ${media.M`
    margin-top: 46px;
  `};
  ${media.L`
    margin-top: 46px;
  `};
`;

export const HeroTextBlockTitle = styled(MainHeading)`
  margin: 4px auto 35px;
`;

export const HeroTextBlockLabel = styled.p`
  ${fonts.GoudyOldStyleItalic};
  font-size: 18px;
  margin: 0;
  text-align: center;
  color: ${props => props.theme.color.black};
`;

export const HeroTextBlockDescription = styled.div`
  ${fonts.GoudyOldStyleRegular};
  font-size: 16px;
  max-width: 470px;
  line-height: 24px;
  color: ${props => props.theme.color.black};
  text-align: center;
  margin-bottom: 30px;

  ${media.M`
    margin-bottom: 0;
  `};

  p {
    font-size: inherit;
    margin: 0;
  }
`;
