import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash/fp';
import { SubHeading } from '../../components/SubHeading/SubHeading';
import { ContainerRoot } from '../../components/Container/styles';
import { PuffList } from '../../components/PuffList/PuffList';
import { Conditional } from '../../components/Conditional/Conditional';

export const InformationBlock = props => {
  const {
    draftId,
    title,
    puffFieldCollection,
    hasFirstHeading,
    presentationMode,
    displayOnMobile,
    displayOnDesktop,
    preserveImageAspectRatio,
    headingFont
  } = props;

  if (isEmpty(puffFieldCollection)) return false;

  return (
    <ContainerRoot
      as="section"
      data-litium-block-id={draftId}
      displayOnDesktop={displayOnDesktop}
      displayOnMobile={displayOnMobile}
      fullWidthMobile
    >
      <Conditional show={!!title}>
        <SubHeading centerMobile as={hasFirstHeading ? 'h1' : 'h2'}>
          {title}
        </SubHeading>
      </Conditional>
      <PuffList
        puffs={puffFieldCollection}
        grid={[1, 1 / 3]}
        breakpoints={['680px']}
        presentationMode={presentationMode}
        preserveAspectRatio={preserveImageAspectRatio}
        headingFont={headingFont}
      />
    </ContainerRoot>
  );
};

InformationBlock.defaultProps = {
  draftId: undefined,
  title: undefined,
  puffFieldCollection: [],
  hasFirstHeading: false,
  presentationMode: null,
  displayOnMobile: true,
  displayOnDesktop: true,
  preserveImageAspectRatio: true,
  headingFont: ''
};

InformationBlock.propTypes = {
  draftId: PropTypes.string,
  title: PropTypes.string,
  puffFieldCollection: PropTypes.arrayOf(PropTypes.object),
  hasFirstHeading: PropTypes.bool,
  presentationMode: PropTypes.string,
  displayOnMobile: PropTypes.bool,
  displayOnDesktop: PropTypes.bool,
  preserveImageAspectRatio: PropTypes.bool,
  headingFont: PropTypes.string
};
