import React from 'react';
import PropTypes from 'prop-types';
import { get, isEmpty } from 'lodash/fp';
import { Box, Flex } from 'rebass';
import { ThemeProvider } from 'styled-components';
import { Conditional } from '../../components/Conditional/Conditional';
import { SubHeading } from '../../components/SubHeading/SubHeading';
import {
  ContainerRoot,
  HorizontalPaddingContainer,
} from '../../components/Container/styles';
import { connect } from '../../store/store';
import { ProductCard } from '../../components/ProductCard/ProductCard';
import {
  FeatureProductsBlockButton,
  FeaturedProductButtonContainer,
} from '../FeaturedProductsBlock/styles';
import { LocalizedLink } from '../../components/LocalizedLink/LocalizedLink';

export const GroupedProductWithImageBlockComponent = (props) => {
  const {
    title,
    products,
    breakpoints,
    gridMargin,
    gridItemPadding,
    grid,
    hasFirstHeading,
    buttonCollection,
    buttonColor,
    displayOnDesktop,
    displayOnMobile,
    disabledFeatures,
    parentIndex,
  } = props;

  if (isEmpty(products)) return false;

  const theme = {
    breakpoints,
  };

  return (
    <ContainerRoot
      as="section"
      noPadding
      mergeMarginsWhenGrouped={!title}
      data-litium-block-id={get('draftId', props)}
      displayOnDesktop={displayOnDesktop}
      displayOnMobile={displayOnMobile}
    >
      <Conditional show={!!title}>
        <HorizontalPaddingContainer>
          <SubHeading centerMobile as={hasFirstHeading ? 'h1' : 'h2'}>
            {title}
          </SubHeading>
        </HorizontalPaddingContainer>
      </Conditional>

      <HorizontalPaddingContainer>
        <ThemeProvider theme={theme}>
          <Flex flexWrap="wrap" justifyContent="center" mx={gridMargin}>
            {products.map((productObject, index) => {
              const product = get('product', productObject);

              if (!product) return null;

              return (
                <Box
                  key={`${parentIndex}-${get('id', product)}`}
                  width={grid}
                  px={gridItemPadding}
                  py={10}
                  style={{ display: 'inline-block' }}
                >
                  <ProductCard
                    listIndex={index}
                    product={product}
                    coverImage={get(['images', 0], productObject)}
                    disabledFeatures={disabledFeatures}
                  />

                  {buttonCollection && buttonCollection[index] && (
                    <FeaturedProductButtonContainer>
                      <LocalizedLink
                        key={buttonCollection[index].link.id}
                        as={buttonCollection[index].link.id}
                        data={{ id: buttonCollection[index].link.id }}
                        page={buttonCollection[index].link.type}
                      >
                        <FeatureProductsBlockButton
                          isBlack={buttonColor === 'whiteOnBlack'}
                          isWhite={buttonColor === 'blackOnWhite'}
                          whiteBorder={buttonColor === 'whiteBorder'}
                          blackBorder={buttonColor === 'blackBorder'}
                        >
                          {buttonCollection[index].heading}
                        </FeatureProductsBlockButton>
                      </LocalizedLink>
                    </FeaturedProductButtonContainer>
                  )}
                </Box>
              );
            })}
          </Flex>
        </ThemeProvider>
      </HorizontalPaddingContainer>
    </ContainerRoot>
  );
};

GroupedProductWithImageBlockComponent.defaultProps = {
  title: null,
  products: [],
  breakpoints: ['480px', '820px', '1280px'],
  grid: [1 / 2, 1 / 2, 1 / 3, 1 / 4],
  gridItemPadding: ['6px', '6px', '15px'],
  gridMargin: ['-6px', '-6px', '-15px'],
  hasFirstHeading: false,
  buttonCollection: [],
  buttonColor: null,
  displayOnMobile: true,
  displayOnDesktop: true,
  disabledFeatures: [],
  parentIndex: 0,
};

GroupedProductWithImageBlockComponent.propTypes = {
  title: PropTypes.string,
  products: PropTypes.array,
  breakpoints: PropTypes.arrayOf(PropTypes.string),
  grid: PropTypes.array,
  gridItemPadding: PropTypes.array,
  gridMargin: PropTypes.array,
  hasFirstHeading: PropTypes.bool,
  buttonCollection: PropTypes.array,
  buttonColor: PropTypes.string,
  displayOnMobile: PropTypes.bool,
  displayOnDesktop: PropTypes.bool,
  disabledFeatures: PropTypes.array,
  parentIndex: PropTypes.number,
};

export const GroupedProductWithImageBlock = connect(
  GroupedProductWithImageBlockComponent
);
