import React from 'react';
import PropTypes from 'prop-types';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation, Autoplay } from 'swiper';

import { ContainerRoot } from '../../components/Container/styles';
import { HeroImageBlock } from '../HeroImageBlock/HeroImageBlock';
import { HeroImageSwiper } from './styles';

import 'swiper/css/navigation';
import 'swiper/css/pagination';

export const HeroSwipeBlock = ({
  displayOnDesktop,
  displayOnMobile,
  heroSwipeImages,
  autoplayDelay,
}) => {
  if (heroSwipeImages.length === 0) return null;
  if (heroSwipeImages.length === 1)
    return (
      <HeroImageBlock
        {...heroSwipeImages[0]}
        displayOnMobile={displayOnMobile}
        displayOnDesktop={displayOnDesktop}
      />
    );

  const autoplay =
    typeof autoplayDelay === 'number' ? { delay: autoplayDelay } : false;
  return (
    <ContainerRoot
      as="section"
      noPadding
      noMargin
      displayOnMobile={displayOnMobile}
      displayOnDesktop={displayOnDesktop}
    >
      <HeroImageSwiper>
        <Swiper
          modules={[Pagination, Navigation, Autoplay]}
          navigation
          spaceBetween={0}
          slidesPerView={1}
          onSlideChange={() => {}}
          onSwiper={() => {}}
          grabCursor={false}
          keyboard
          pagination={{
            clickable: true,
          }}
          autoplay={autoplay}
          speed={800}
        >
          {heroSwipeImages.map((block, idx) => {
            const blockProps = {
              ...block,
              ...{ displayOnDesktop, displayOnMobile },
              enableClipPath: false,
            };
            return (
              <SwiperSlide key={`${block.id || 'hero-image-block'}-${idx}`}>
                <HeroImageBlock {...blockProps} />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </HeroImageSwiper>
    </ContainerRoot>
  );
};

HeroSwipeBlock.defaultProps = {
  heroSwipeImages: [],
  autoplayDelay: undefined,
  id: '',
};

HeroSwipeBlock.propTypes = {
  autoplayDelay: PropTypes.number,
  displayOnDesktop: PropTypes.bool.isRequired,
  displayOnMobile: PropTypes.bool.isRequired,
  heroSwipeImages: PropTypes.arrayOf(
    PropTypes.shape({
      isMobile: PropTypes.bool,
      isFirstBlock: PropTypes.bool,
      hasFirstHeading: PropTypes.bool,
      buttonColor: PropTypes.string,
      useHeroImageFilter: PropTypes.bool,
      multipleSizeImageContainer: PropTypes.object,
      displayOnMobile: PropTypes.bool,
      displayOnDesktop: PropTypes.bool,
    })
  ),
  id: PropTypes.string,
  type: PropTypes.string.isRequired,
};
