import styled, { css } from 'styled-components';
import { fonts } from '../../styles/typography';
import { dynamicFontSize, hover, media } from '../../styles/mixins';
import { RocketImage } from '../RocketImage/RocketImage';
import { VideoBackground } from '../VideoBackground/VideoBackground';

export const PuffRoot = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  margin: ${({ enableMarginBlock }) => (enableMarginBlock ? '20px 0' : 0)};
  color: ${(props) => props.theme.color.black};
  overflow: hidden;
`;

export const PuffHeading = styled.a`
  ${({ headingFont }) =>
    headingFont === 'NeoSans'
      ? fonts.NeoSansRegular
      : fonts.GoudyOldStyleRegular};
  font-size: 16px;
  max-width: 960px;
  color: #000 !important;
  width: 100%;
  display: block;
  margin: 0 0 10px;
  text-decoration: none;
  opacity: 1;
  transition: opacity 0.1s ease-in-out;
  ${hover`
    opacity: 0.45;
  `};
  ${(props) =>
    props.hide &&
    css`
      display: none;
    `};
  ${(props) =>
    props.menu &&
    css`
      //TODO: Add style from ListItemLink
    `};
`;

export const PuffDescription = styled.p`
  ${fonts.GoudyOldStyleRegular};
  font-size: 14px;
  ${(props) =>
    props.menu &&
    css`
      color: #000 !important;
      line-height: 20px;
      margin: 10px 0 0 0;
    `};
  ${(props) =>
    !props.menu &&
    css`
      line-height: 1.43;
      overflow-y: hidden;
      margin: 0 auto 7px;
      width: 100%;
      max-width: 320px;
      letter-spacing: normal;
      text-align: center;
    `};
  ${(props) =>
    props.lines &&
    props.lines === 2 &&
    css`
      overflow-x: hidden;
      span > span > span {
        white-space: nowrap;
      }
    `};
  > p {
    font-size: inherit;
    margin: 0;
  }
`;

export const PuffVideo = styled(VideoBackground)`
  transition: transform 0.6s ease-out;
  ${hover`
    transform: scale(1.08);
  `};
`;

export const PuffImageContainer = styled.div`
  position: relative;
  overflow: hidden;
  margin-bottom: ${({ enableMarginBottom }) =>
    enableMarginBottom ? '15px' : 0};
  ${({ displayFullHeightVideo }) =>
    displayFullHeightVideo &&
    css`
      display: contents;

      ${PuffVideo} {
        height: 100%;
      }
    `};
`;

export const PuffImage = styled(RocketImage)`
  width: 100%;
  margin: 0;

  img {
    height: unset;
    width: 100%;
    max-width: 100%;
  }
`;

export const PuffImageOverlay = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  align-items: flex-end;
  justify-content: center;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  min-height: 100px;
  padding: 1rem 1rem 1.5rem;
  background-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.4)
  );
  color: white;
  font-size: 1.625rem;
  // Allow hover event on actual image behind
  pointer-events: ${({ pointerEvents }) => pointerEvents};

  ${(props) =>
    props.center &&
    css`
      top: 0;
      align-items: center;
      padding-top: 0;
      padding-bottom: 0;
      background-image: none;
      background-color: rgba(0, 0, 0, 0.2);
    `}
`;

export const PuffImageOverlayTitle = styled.span`
  max-width: 40ch;
  ${dynamicFontSize(18, 34)};
  ${({ headingFont }) =>
    headingFont === 'NeoSans'
      ? fonts.NeoSansRegular
      : fonts.GoudyOldStyleRegular};
`;

export const PuffLabel = styled.p`
  ${fonts.GoudyOldStyleItalic};
  font-size: 18px;
  margin: 8px 0;
`;

export const PuffExternalLink = styled.a`
  display: block;
  text-decoration: none;
`;

export const MagicButtonContainer = styled.div`
  inset: auto 16px 25px 16px;
  position: ${({ stickToBottom }) => (stickToBottom ? 'absolute' : 'static')};

  ${media.M`
  inset: auto auto 25px auto;
  `};
`;
