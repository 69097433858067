import React from 'react';
import PropTypes from 'prop-types';
import { size, getOr, get, map, flow, entries, reduce } from 'lodash/fp';
import styled from 'styled-components';
import { withRouter } from 'next/router';
import { compose } from 'redux';
import { withCookies } from 'react-cookie';
import { fonts } from '../../styles/typography';
import { FilterTagsHeight } from '../../styles/animations';
import { Icon } from '../Icon/Icon';
import { ICONS } from '../../constants/icons';
import { ProductList } from '../ProductList/ProductList';
import { Container } from '../Container/Container';
import { Tag } from './styles';
import { ProductFilters } from './ProductFilters';
import { connect } from '../../store/store';
import { removeViewstate } from '../../store/actions/actions';
import { getProductsByCategory } from '../../store/selectors/selectProducts';
import { Conditional } from '../Conditional/Conditional';
import { hover } from '../../styles/mixins';
import { color } from '../../styles/variables';
import { LAST_LIST_VIEW } from '../../constants/cookies';

class ProductListWithFiltersComponent extends React.Component {
  constructor(props) {
    super(props);
    const { cookies, router } = props;
    let limit = process.env.NEXT_PUBLIC_PRODUCTS_LIMIT;
    const lastListView = cookies.get(LAST_LIST_VIEW);

    if (lastListView && lastListView.path === router.asPath) {
      limit = lastListView.productsLength;
    }

    this.state = {
      maxVisibleProducts: limit,
    };

    this.setActiveFilterOptions = this.setActiveFilterOptions.bind(this);
    this.removeFilter = this.removeFilter.bind(this);
    this.renderFilterTagItems = this.renderFilterTagItems.bind(this);
    this.renderFilterTags = this.renderFilterTags.bind(this);
    this.renderAllFilters = this.renderAllFilters.bind(this);
  }

  setActiveFilterOptions(index) {
    this.setState((state) => ({
      activeFilterOption: state.activeFilterOption === index ? -1 : index,
    }));
  }

  removeFilter(path) {
    this.props.removeViewstate(path);
  }

  renderAllFilters() {
    let sumOfAllSelectedFilterOptions = 0;
    if (this.props.viewstate.filter) {
      sumOfAllSelectedFilterOptions = reduce(
        (sum, n) => sum + size(n),
        0,
        this.props.viewstate.filter
      );

      return sumOfAllSelectedFilterOptions > 0 ? (
        <span>({sumOfAllSelectedFilterOptions})</span>
      ) : null;
    }
    return null;
  }

  renderFilterTagItems(entry) {
    const key = entry[0];
    const data = entry[1];

    return flow(
      entries,
      map((activeEntry) => {
        const activeKey = activeEntry[0];
        const activeData = activeEntry[1];

        return (
          <FilterTagsHeight key={activeData.optionTitle} timeout={300}>
            <Tag
              onClick={() => this.removeFilter(['filter', key, activeKey])}
              as="div"
            >
              {activeData.filterTitle}: {activeData.optionTitle}
              <Icon icon={ICONS.CLOSE} size={8} />
            </Tag>
          </FilterTagsHeight>
        );
      })
    )(data);
  }

  renderFilterTags() {
    return flow(
      entries,
      map((value) => this.renderFilterTagItems(value))
    )(getOr([], ['viewstate', 'filter'], this.props));
  }

  render() {
    const allowlist = this.props.availableProductFilters || [];
    const filters = allowlist.reduce((memo, item) => {
      // eslint-disable-next-line no-param-reassign
      memo[item] = this.props.filters[item];
      return memo;
    }, {});

    const hasFilters = Object.keys(filters).length > 0;

    return (
      <Container noPadding>
        {hasFilters && (
          <ProductFilters
            filteredProducts={this.props.filteredProducts}
            filters={filters}
          />
        )}
        <ProductList
          currentPage={this.props.currentPage}
          market={this.props.market}
          products={this.props.filteredProducts.slice(
            0,
            this.state.maxVisibleProducts
          )}
          saveNumberOfLoadedProducts
          productListContent={this.props.productListContent}
        />
        <Conditional
          show={
            this.state.maxVisibleProducts < size(this.props.filteredProducts)
          }
        >
          <LoadMoreButton
            onClick={() =>
              this.setState(() => ({
                maxVisibleProducts: size(this.props.products),
              }))
            }
          >
            {get('loadMore', this.props.websiteStrings)}
          </LoadMoreButton>
        </Conditional>
      </Container>
    );
  }
}

ProductListWithFiltersComponent.defaultProps = {
  currentPage: {},
  market: '',
  viewstate: {},
  filteredProducts: [],
  products: {},
  filters: {},
  availableProductFilters: [],
  productListContent: [],
};

ProductListWithFiltersComponent.propTypes = {
  currentPage: PropTypes.object,
  market: PropTypes.string,
  router: PropTypes.object.isRequired,
  removeViewstate: PropTypes.func.isRequired,
  viewstate: PropTypes.object,
  filteredProducts: PropTypes.array,
  websiteStrings: PropTypes.object.isRequired,
  filters: PropTypes.object,
  products: PropTypes.object,
  availableProductFilters: PropTypes.array,
  cookies: PropTypes.object.isRequired,
  productListContent: PropTypes.array,
};

export const ProductListWithFilters = compose(withRouter, withCookies, connect)(
  ProductListWithFiltersComponent,
  { removeViewstate },
  (store, props) => {
    return {
      viewstate: store.viewState,
      websiteStrings: get(['bootstrap', 'data', 'websiteStrings'], store),
      filteredProducts: getProductsByCategory(store, props),
    };
  }
);

export const LoadMoreButton = styled.button`
  ${fonts.NeoSansRegular};
  font-size: 14px;
  position: relative;
  color: ${color.white};
  background-color: ${(props) => props.theme.color.black};
  border: none;
  display: block;
  cursor: pointer;
  &:focus {
    outline: none;
  }
  padding: 18px 20px;
  margin: 0 auto;
  transition: background-color 0.25s ease 0s, color 0.25s ease 0s,
    border-color 0.25s ease 0s;
  ${hover`
    color: ${color.black};
    background-color: ${(props) => props.theme.color.primary};
    border-color: #000;
  `};
`;
