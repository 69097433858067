import styled, { css } from 'styled-components';
import { hover, media } from '../../styles/mixins';
import { fonts } from '../../styles/typography';
import { color } from '../../styles/variables';
import { TransitionGroup } from 'react-transition-group';

export const OuterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e5e5e5;
`;

export const NumOfProductsTag = styled.div`
  ${fonts.NeoSansLight};
  font-size: 14px;
  ${(props) =>
    props.align &&
    props.align === 'center' &&
    css`
      text-align: center;
    `};
  ${(props) =>
    props.mb !== 'undefined' &&
    css`
      margin-bottom: ${props.mb}px;
    `};
`;

export const TagContainer = styled.div`
  position: relative;

  width: 100%;
  margin-top: 20px;
  margin-bottom: 5px;
`;

export const Tag = styled.li`
  cursor: pointer;
  background-color: ${color.lightGrey};
  ${fonts.NeoSansRegular};
  font-size: 13px;
  padding: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  &:last-child {
    margin-right: 0;
  }
  ${(props) =>
    props.clear &&
    css`
      background-color: #fff;
    `};
  > svg {
    margin-left: 10px;
  }
  transition: opacity 0.1s ease-in-out;
  ${hover`
    opacity: .45;
  `};
`;

export const Tags = styled(TransitionGroup)`
  position: static;
  top: 0;
  left: 0;
  list-style: none;

  flex-wrap: wrap;
  padding: 0;
  margin: 0;

  display: flex;
  align-items: center;
  justify-content: flex-start;

  > div {
    margin-right: 10px;
    margin-bottom: 10px;
    &:last-child {
      margin-right: 0;
    }
  }
`;

export const Circle = styled.div`
  position: absolute;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  background-color: ${(props) => props.color};
`;

export const ColorItem = styled.li`
  position: relative;
  margin: 0 !important;
  width: 11px;
  height: 16px;
`;

export const ColorCircleList = styled.ul`
  list-style: none;
  padding: 0;
  display: flex;
  position: relative;
  right: 4px;
`;

export const FilterOptionBtn = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  &:focus {
    outline: none;
  }
  margin: 0;
  padding: 0;

  opacity: 1;
  transition: opacity 0.1s ease-in-out;
  ${hover`
    opacity: 0.45;
  `};
  ${media.IE`
    height: 16px;
  `};
`;

export const TextLabel = styled.label`
  flex: 1;
  cursor: pointer;
  ${(props) =>
    props.mobile &&
    css`
      font-size: 14px;
    `};

  ${(props) =>
    props.soldOut &&
    css`
      cursor: not-allowed;
    `};
`;

export const TextSoldOut = styled.label`
  font-size: 12px;
  opacity: 0.45;
  cursor: not-allowed;
`;

export const ChoiceText = styled.span`
  transition: opacity 0.1s ease-in-out;
  ${hover`
    opacity: .45;
  `};
`;

export const SustIcon = styled.div`
  ${fonts.NeoSansLight};
  display: flex;
  align-items: center;
  line-height: 20px;
  letter-spacing: 0;
  font-size: 13px;
  margin-left: 30px;
  &:first-child {
    margin-left: 0;
  }
  span {
    display: inline-block;
    margin-left: 0;
    margin-top: 5px;
  }
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (min-width: 440px) {
    flex-direction: column;
    margin-left: 20px;
    span {
      margin-left: 8px;
      margin-top: 0;
    }
  }

  img {
    width: 16px !important;
    height: auto;
    ${media.IE`
      height: 16px;
    `};
  }
`;

export const CheckBoxLabel = styled.label`
  border: 1px solid ${color.borderGrey};
  display: block;
  min-width: 16px;
  width: 16px;
  height: 16px;
  margin: 0 14px 0 0;
  ${(props) =>
    props.mobile &&
    css`
      width: 22px;
      height: 22px;
      min-width: 22px;
      background-color: #fff;
    `};
}
`;

export const CheckBox = styled.input.attrs({
  type: 'checkbox',
})`
  &:checked,
  &:not(:checked) {
    position: absolute;
    left: -999999px;
  }
  &:checked + label,
  &:not(:checked) + label {
    position: relative;
    cursor: pointer;
  }
  &:checked + label:before,
  &:checked + label:after {
    content: '';
    position: absolute;
    width: 1px;
    height: 18px;
    position: absolute;
    left: 7px;
    top: -2px;
    transform: rotate(45deg);
    background-color: #979797;
  }
  &:checked + label:after {
    transform: rotate(-45deg);
  }

  &:not(:checked) + label:before,
  &:not(:checked) + label:after {
  }

  &:disabled + label {
    cursor: not-allowed;
  }

  ${(props) =>
    props.mobile &&
    css`
      &:checked + label:before,
      &:checked + label:after {
        height: 26px;
        top: -3px;
        left: 9px;
      }
    `};

  ${({ loading }) =>
    loading &&
    css`
      + label {
        animation: breathe 1s ease-in-out infinite alternate;

        @keyframes breathe {
          0% {
            border-color: ${color.borderGrey};
          }
          100% {
            border-color: ${color.black};
          }
        }
      }
    `};
`;

/* transition: padding-top 0.3s ease-in-out, padding-bottom 0.3s ease-in-out, max-height 0.3s ease-in-out; */

export const DropDownArea = styled.ul`
  position: absolute;
  left: 0;
  top: 28px;
  z-index: 110;
  width: 240px;
  background-color: #fff;
  border: none;
  padding: 0 17px 0 20px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  max-height: 0;

  ${(props) =>
    props.items &&
    props.mobile &&
    css`
      max-height: ${31 * props.items + 44}px;
    `};

  ${(props) =>
    props.active &&
    !props.mobile &&
    css`
      max-height: ${31 * props.items + 44}px;
      padding-top: 27px;
      padding-bottom: 17px;
      border: 1px solid ${color.borderGrey};
    `};

  ${(props) =>
    props.active &&
    props.mobile &&
    css`
      padding-top: 27px;
      padding-bottom: 17px;
      border: 1px solid ${color.borderGrey};
    `};

  li {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-left: 0;
    margin-bottom: 15px;
  }

  ${(props) =>
    props.mobile &&
    css`
      position: static;
      background-color: transparent;
      width: 100%;
    `};
`;

export const FilterListItem = styled.li`
  ${fonts.NeoSansRegular};
  font-size: 14px;
  margin-left: 30px;
  position: relative;
  &:after {
    content: '';
    position: absolute;
    height: 1px;
    width: 100%;
    display: block;
    background-color: ${color.borderGrey};
    z-index: 110;
    right: 0;
    bottom: -13px;
  }

  ${(props) =>
    props.active &&
    css`
      &:after {
        background-color: #000;
      }
      > button svg {
        transform: rotateX(180deg);
      }
    `};
  > button svg,
  > svg {
    width: 8px;
    height: auto;
    margin-left: 8px;
  }
  &:first-child {
    margin-left: 0;
  }
`;

export const OuterFilterList = styled.ul`
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 0 12px 0;
  margin-bottom: 0;
`;

export const FilterBtnLink = styled.button`
  border: 1px solid ${color.hoverGrey};
  color: ${color.black};
  background-color: #fff;
  outline: 0;
  cursor: pointer;
  display: block;
  margin: 0 auto 20px;
  width: 100%;

  p {
    ${fonts.NeoSansRegular};
    font-size: 14px;
    margin: 16px 0 14px;
  }
  span {
    ${fonts.NeoSansLight};
    font-size: 14px;
    margin-left: 5px;
  }
`;

export const ClearBtn = styled.button`
  border: 1px solid ${color.hoverGrey};
  color: ${color.black};
  ${fonts.NeoSansRegular};
  font-size: 13px;
  background-color: #fff;
  outline: 0;
  cursor: pointer;
  display: inline-block;
  margin-top: 30px;
  padding: 10px 15px;
`;

export const FilterButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
