import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Modal } from '../Modal/Modal';
import { FilterBtnLink, ClearBtn, FilterButtonContainer } from './styles';
import { Accordion } from '../Accordion/Accordion';
import { SubHeading } from '../SubHeading/SubHeading';
import { String } from '../String/String';

export const ProductMobileFilters = ({
  renderNumOfAllFilters,
  showModal,
  handleShowModal,
  hasFilters,
  accordionChildren,
  removeFilter,
  closeModals
}) => (
  <Fragment>
    <FilterBtnLink onClick={handleShowModal}>
      <p>
        <String id="filterLabel" /> {renderNumOfAllFilters}
      </p>
    </FilterBtnLink>
    <Modal onClose={handleShowModal} in={showModal} slideIn fromLeft>
      <SubHeading mt={0} mb={60}>
        <String id="filterLabel" />
      </SubHeading>
      <Accordion>{accordionChildren}</Accordion>

      <FilterButtonContainer>
        {hasFilters ? (
          <ClearBtn onClick={removeFilter}>
            <String id="filterClearAllLabel" />
          </ClearBtn>
        ) : (
          <span />
        )}

        <ClearBtn onClick={closeModals}>
          <String id="filterApplyLabel" />
        </ClearBtn>
      </FilterButtonContainer>
    </Modal>
  </Fragment>
);

ProductMobileFilters.defaultProps = {
  renderNumOfAllFilters: {},
  showModal: false,
  hasFilters: false,
  accordionChildren: []
};

ProductMobileFilters.propTypes = {
  renderNumOfAllFilters: PropTypes.object,
  showModal: PropTypes.bool,
  handleShowModal: PropTypes.func.isRequired,
  hasFilters: PropTypes.bool,
  accordionChildren: PropTypes.array,
  removeFilter: PropTypes.func.isRequired,
  closeModals: PropTypes.func.isRequired
};
