import { createSelector } from 'reselect';
import { compose, filter, find, get, getOr, isEmpty, last, map, pickBy, set, split } from 'lodash/fp';

// const selectProductsByFilter = (store, filterKeyArray, filterValue) =>
//   pickBy(item => {
//     // If the product does not contain the filterValue, exclude it.
//     if (!get(filterKeyArray, item)) {
//       return false;
//     }
//     return get(filterKeyArray, item) === filterValue;
//   })(store.products);
//
// const selectProductsByFilters = (store, group, filterKeyArray, filterValue) =>
//   pickBy(item => {
//     // If the product does not contain the filterValue, exclude it.
//     if (!get(filterKeyArray, item)) {
//       return false;
//     }
//     const matchedObjects = pickBy(x => get(filterKeyArray, x) === filterValue)(get(group, item));
//     return !isEmpty(matchedObjects);
//   })(get(['products', 'data'], store));
//
// const selectProductsByIncludesFilters = (store, group, filterKeyArray, filterValue) =>
//   pickBy(item => {
//     // If the product does not contain the filterValue, exclude it.
//     if (!get(filterKeyArray, item)) {
//       return false;
//     }
//     const matchedObjects = pickBy(x => get(filterKeyArray, x) && get(filterKeyArray, x).indexOf(filterValue) === 0)(
//       get(group, item)
//     );
//     return !isEmpty(matchedObjects);
//   })(get(['products', 'data'], store));

const getFilteredProductVariants = (sizes, colors, products) => {
  // This array will include all active filter functions.
  const filters = [];

  if (Object.keys(colors).length) {
    // Convert filter objects to arrays.
    // { Reds: {}, Blues: {} } --> ["Reds", "Blues"]
    const colorsArray = Object.keys(colors);

    // This filter function uses the local colorsArray and takes a variant.
    // Filters variants that has at least one of the selected colors.
    const hasVariantInColor = variant => colorsArray.includes(get(['color', 'filterColor'], variant));

    filters.push(hasVariantInColor);
  }

  if (Object.keys(sizes).length) {
    // Convert filter objects to arrays.
    // { Reds: {}, Blues: {} } --> ["Reds", "Blues"]
    const sizesArray = Object.keys(sizes);

    // This filter function uses the local sizeArray and takes a variant.
    // Filters variants that has at least one of the selected size in stock.
    const hasVariantInCorrectSizeInStock = variant =>
      find(
        variantSize => get('stockBalance', variantSize) > 0 && sizesArray.includes(get('shortTitle', variantSize)),
        get('sizes', variant)
      ) !== undefined;

    filters.push(hasVariantInCorrectSizeInStock);
  }

  // Iterate over filter functions and filter variants from products.
  const productsWithFilteredVariants = filters.reduce((productsMemo, filterFunction) => {
    return map(product => {
      const variants = get('variants', product).filter(filterFunction);
      return set('variants', variants, product);
    }, productsMemo);
  }, products);

  // Remove products without any variants left.
  return filter(product => !isEmpty(product.variants), productsWithFilteredVariants);
};

const selectFilteredProduct = (genders, activites, categories, functions, sustainabilityOptions, products) => {
  const getFilteredProducts = compose(
    pickBy(x =>
      isEmpty(activites)
        ? true
        : !isEmpty(pickBy(y => Object.keys(activites).includes(y.id))(getOr([], 'activities', x)))
    ),
    pickBy(x =>
      isEmpty(categories)
        ? true
        : !isEmpty(pickBy(y => Object.keys(categories).includes(last(split('/', y.id))))(getOr([], 'categories', x)))
    ),
    pickBy(x =>
      isEmpty(genders) ? true : !isEmpty(pickBy(y => Object.keys(genders).includes(y.id))(getOr([], 'gender', x)))
    ),
    pickBy(x =>
      isEmpty(functions) ? true : !isEmpty(pickBy(y => Object.keys(functions).includes(y.id))(getOr([], 'function', x)))
    ),
    pickBy(x =>
      isEmpty(sustainabilityOptions)
        ? true
        : !isEmpty(
            pickBy(y => Object.keys(sustainabilityOptions).includes(y.id))(getOr([], 'sustainabilityOptions', x))
          )
    )
  );

  return getFilteredProducts(products);
  // return getFilteredProductVariants(store, composed);
};

// export const getProductsByKey = createSelector(
//   [selectProductsByFilter],
//   selectedProducts => selectedProducts
// );
// export const getProductsByKeys = createSelector(
//   [selectProductsByFilters],
//   selectedProducts => selectedProducts
// );
// export const getProductsByIncludesKeys = createSelector(
//   [selectProductsByIncludesFilters],
//   selectedProducts => selectedProducts
// );
// export const getFilteredProducts = createSelector(
//   [selectFilteredProduct],
//   selectedProducts => selectedProducts
// );

// const getCategoryId = (state, props) =>
//   props.id;

export const getProducts = (state, props) => props.products;

const getActivityFilter = state => getOr([], ['viewState', 'filter', 'activity'], state);

const getGenderFilter = state => getOr([], ['viewState', 'filter', 'gender'], state);

const getCategoryFilter = state => getOr([], ['viewState', 'filter', 'category'], state);

const getFunctionFilter = state => getOr([], ['viewState', 'filter', 'function'], state);

const getSustainabilityOptionFilter = state => getOr([], ['viewState', 'filter', 'sustainabilityOption'], state);

const getSizeFilter = state => getOr([], ['viewState', 'filter', 'size'], state);

const getColorFilter = state => getOr([], ['viewState', 'filter', 'color'], state);

export const getProductsByCategory = createSelector(
  [
    getGenderFilter,
    getActivityFilter,
    getCategoryFilter,
    getFunctionFilter,
    getSustainabilityOptionFilter,
    getSizeFilter,
    getColorFilter,
    getProducts
  ],
  (genders, activities, categories, functions, sustainabilityOptions, sizes, colors, products) => {
    // Remove products that does not match the filters.
    const selectedProducts = selectFilteredProduct(
      genders,
      activities,
      categories,
      functions,
      sustainabilityOptions,
      products
    );

    // Remove products that does not have variants that match the filters.
    return getFilteredProductVariants(sizes, colors, selectedProducts);
  }
);
