import React from 'react';
import { get } from 'lodash/fp';
import PropTypes from 'prop-types';

import { ContainerRoot } from '../../components/Container/styles';
import { NewsletterSignup } from '../../components/NewsletterSignup/NewsletterSignup';

export const NewsletterBlock = props => (
  <ContainerRoot
    as="section"
    data-litium-block-id={get('draftId', props)}
    displayOnMobile={props.displayOnMobile}
    displayOnDesktop={props.displayOnDesktop}
  >
    <NewsletterSignup heading={get('heading', props)} body={get('body', props)} type="block" />
  </ContainerRoot>
);

NewsletterBlock.defaultProps = {
  displayOnMobile: true,
  displayOnDesktop: true
};

NewsletterBlock.propTypes = {
  displayOnMobile: PropTypes.bool,
  displayOnDesktop: PropTypes.bool
};
